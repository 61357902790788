.Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--lawmonitor-gray-100);
    opacity: 0.9;
    z-index: 2000;
}

.Popup {
    z-index: 8000;
    opacity: 1;
    position: fixed;
    top: 33%;
    left: 42%;
    display: flex;
    align-items: center;
    margin-left: auto;
    font-family: var(--lawmonitor-proxima-soft);
}

.PopupContent {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: #3e3e3c;
    background-color: white;
    box-shadow: -6px 4px 8px rgba(0, 0, 0, 0.2);
    border: solid 1px var(--lawmonitor-gray-400);
    min-width: 400px;
    min-height: 200px;
    border-radius: 5px;
    padding: 21px;
    z-index: 8000;
}

.Header h1 {
    font-size: 25px;
    margin: 0;
}

.Text {
    font-size: 17px;
    line-height: 1.3;
    margin: 0;
    color: var(--lawmonitor-gray-400);
}

.Footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 1px;
}

.Footer > button {
    font-size: 12px;
    width: 100px;
}
