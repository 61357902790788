.ContractPage {
    display: flex;
    flex-direction: column;
    color: var(--lawmonitor-gray-500);
    padding-left: 24px;
    margin-bottom: 20px;
}
.ContractHeader {
    font-size: 18px;
}

.Contract {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 800px;
    margin-bottom: 39px;
    background-color: var(--lawmonitor-gray-100);
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-300);
}

.Contract table {
    width: 300px;
}

.Contract h4 {
    font-size: 14px;
}