.EditDetachedCopies {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.List {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.TableWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
