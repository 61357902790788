.Modal {
    width: 100%;
    max-width: 600px;
    padding: 0;
}
.Modal h4 {
    margin-top: 24px;
    margin-left: 32px;
    margin-bottom: 6px;
}

.Container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
    padding: 24px 32px;
}
.Content > h4 {
    margin: 0;
}

.Section {
    display: flex;
    justify-content: space-between;
}

.DropdownInput {
    max-width: 250px;
    width: 100%;
}
.DropdownInput > p {
    margin-top: 0;
    margin-bottom: 6px;
}
.DropdownInput > div,
.DropdownInput > div > div {
    width: 100%;
}

.ToggleContainer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ToggleContainer > div {
    margin-right: 0;
    width: auto;
}
.ToggleContainer > p {
    margin: 0;
}
.ToggleContainer.MT {
    margin-top: 16px;
}

.Footer {
    padding: 24px 32px;
    display: flex;
    justify-content: flex-end;
}
.Footer button {
    padding: 4px 24px 5px 24px;
}
.Footer button:last-of-type {
    margin-left: 16px;
}
.Footer .RemoveButton {
    flex: 1;
}
.Footer .RemoveButton button {
    margin: 0;
}
