.FilterbarMenuItem {
    position: relative;
    font-size: 12px;
    padding: 8px 24px;
    white-space: nowrap;
    cursor: pointer;
}
.FilterbarMenuItem.HasChildren {
    padding-left: 10px;
}
.FilterbarMenuItem .OpenIcon {
    display: inline;
    padding-right: 8px;
}
.Open .OpenIcon img {
    transform: rotate(90deg);
}

.FilterbarMenuItem:hover {
    background-color: var(--lawmonitor-gray-100);
}

.Open .ColumnList,
.Open .SubItemList {
    position: absolute;
    margin: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    top: -8px;
    right: 100%;
    background-color: #ffffff;
    z-index: 600;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    cursor: default;
}
.ColumnList li,
.SubItemList li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.ColumnList li:hover,
.SubItemList li:hover {
    background-color: var(--lawmonitor-gray-100);
}
.ColumnList li .ColumnTitle {
    padding: 4px 16px;
    width: 100%;
}
/* Checkbox */
.ColumnList li > label {
    padding-right: 16px;
}

.Open .SubItemList {
    top: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.SubItemList li .SubItemTitle {
    padding: 8px 32px;
    width: 100%;
}
