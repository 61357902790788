.RemoveNewsFeedModal {
    max-width: 450px;
    width: 100%;
}

.Options {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.Options button:last-of-type {
    margin-left: 16px;
}
