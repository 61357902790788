.ConsultantMultiAcceptModal {
    overflow: initial;
    width: 100%;
    max-width: 650px;
    padding: 0;
    font-size: 14px;
}

.Content {
    width: 100%;
}
.Content h4 {
    margin: 0;
}

.RadioButtons {
    padding: 28px 32px 32px 32px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}

.Comment {
    padding: 16px 32px;
    height: 150px;
}
.Comment h5 {
    font-size: 14px;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 8px 32px 32px 32px;
}
.Buttons button:last-of-type {
    margin-left: 16px;
}
