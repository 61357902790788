.NewsFeed {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-200);
    margin-bottom: 24px;
    position: relative;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--lawmonitor-gray-100);
    height: 40px;
    padding: 10px 20px;
}
.Header h4 {
    font-size: 14px;
    margin: 0;
}
.Header h4:first-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Text {
    padding: 20px 24px;
    font-size: 14px;
}
.Text > * {
    margin: 0;
}

.Toolbar {
    display: flex;
    position: absolute;
    bottom: 2px;
    right: 2px;
}
.Toolbar img {
    cursor: pointer;
    padding: 2px;
}
.Toolbar img:not(.Hide):hover {
    filter: brightness(0);
}

.Toolbar .ArrowUp {
    transform: rotate(90deg);
    margin-right: 10px;
}

.Hide {
    opacity: 0.3;
    cursor: default!important;
}

.Toolbar .ArrowDown {
    transform: rotate(-90deg);
    margin-right: 20px;
}