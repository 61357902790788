
.Modal {
    top: 380px;
    width: 448px;
    height: auto;
}

.Content {
    width: 100%;
}

.ModalConfirm {
    top: 380px;
    width: 448px;
    height: 172px;
}

.ForgotPasswordForm {
    width: 100%;
    height: 100%;

}

.Header {
    font-size: 12px;
    line-height: 0;
    margin-top: 3px;
}

.SubHeaderConfirm {
    font-size: 12px;
    margin-top: 18px;
}


.FormGroup {
    width: 100%;
    font-size: 14px;
    margin-top: 22px;
}

.Input {
    max-width: 500px;
}

.FormGroup input {
    display: block;
    height: 24px;
    font-size: 12px;
    padding: 6px;
    border: 1px solid var(--lawmonitor-gray-500);
    border-radius: 6px;
    overflow: hidden;
}

.FormGroupRow {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    margin-top: 16px;
}

.FormGroupRowConfirm {
    margin-top: 28px;
}

.FormGroupRow button {
    margin-left: 16px;
    font-size: 13px;
}

.Submit {
    height: 25px;
}

@media screen and (max-width: 600px) {
    .Modal{
        width: 90%;
    }
    .ModalConfirm{
        width: 90%;
    }
    .FormGroupRow {
        justify-content: space-between;
    }
    .FormGroupRow button {
        margin-left: 0;
    }
}