.CloseBar {
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    height: 24px;
    min-width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lawmonitor-gray-500);
    font-size: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: var(--lawmonitor-gray-300);
}

.TextEditor {
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    padding: 16px 24px 16px 32px;
    flex: 1;
    max-height: 450px;
}

.Options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px 24px 24px 32px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}

.Options.Disabled {
    opacity: 0.4;
    pointer-events: none;
}
.Options h3 {
    font-size: 13px;
    margin: 0;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 16px 32px;
}

.Buttons button {
    padding: 4px 20px;
    font-size: 12px;
}

.Buttons button:last-of-type {
    margin-left: 16px;
}

.MediaAttachmentWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 25px
}

.MediaThumbnail{
    width: 100px;
    height: 100px;
}

.DataList{
    list-style-type: none;
    padding: 1vh;
    margin: 1vh 1vh 1vh -1vh;
}

.ButtonContainer {
    width: 386px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .RevisionEditor {
        width: 50%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .RevisionEditor {
        width: 50%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .RevisionEditor {
        width: 50%;
    }
}

@media screen and (max-width: 800px) {
    .ButtonContainer {
        width: 300px;
    }
    .MediaAttachmentWrapper{
        margin-top: 0;
    }
}

