.RevisionEditor {
    position: absolute;
    top: 0;
    left: 0;
    width: max(50%, calc(100% - 696px));
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow-y: auto;
    border-left: 1px solid var(--lawmonitor-gray-300);
    border-right: 1px solid var(--lawmonitor-gray-300);
    z-index: 1100;
}

.CloseBar {
    height: 24px;
    min-width: 100%;
    padding: 8px;
    background-color: var(--lawmonitor-gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--lawmonitor-gray-500);
    font-size: 10px;
    margin-top: 10px;
}

.Content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.TextEditor {
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    padding: 16px 24px 16px 32px;
    flex: 1;
    max-height: 450px;
}

.Options {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    padding: 16px 24px 24px 32px;
}
.Options.Disabled {
    opacity: 0.4;
    pointer-events: none;
}
.Options h3 {
    font-size: 13px;
    margin: 0;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 24px 32px;
}

.Buttons button {
    padding: 4px 20px;
    font-size: 12px;
}

.Buttons button:last-of-type {
    margin-left: 16px;
}

.WarningModel {
    left: 50% !important;
    top: 50% !important;
}
/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .RevisionEditor {
        width: 50%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .RevisionEditor {
        width: 50%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .RevisionEditor {
        width: 50%;
    }
}

@media screen and  (max-width: 800px) {
    .RevisionEditor {
        width: 100%;
    }
    .TextEditor {
        border-bottom: none;
    }
    .Buttons {
        justify-content: space-evenly;
    }
    .Buttons button  {
        padding: 5px 60px;
        font-size: 14px;
    }
    textarea {
        font-size: 16px;
    }
    .WarningModel {
        top:70%
    }
}
