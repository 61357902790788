
.RemoveDetachedCopiesModal {
    overflow: initial;
    width: 100%;
}

.Content {
    width: 100%;
    margin-top: 20px;
}

.Warning {
    display: flex;
}

.Warning > img {
    margin-right: 24px;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
}

.Buttons button:last-of-type {
    margin-left: 16px;
}
