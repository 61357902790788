.Dropdown {
    position: relative;
    font-size: 12px;
    cursor: pointer;
}

.Header {
    width: 184px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-500);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    z-index: 1000;
    user-select: none;
    background-color: #ffffff;
}

.Title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
}

.Icon {
    transform: rotate(90deg);
}
.Closed .Icon {
    transform: rotate(0deg);
}

.ListWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 26px;
    left: 0;
    min-width: 184px;
    background-color: #ffffff;
    border: 1px solid var(--lawmonitor-gray-500);
    border-radius: 6px;
    z-index: 1001;
}

.List {
    max-height: 230px;
    overflow-y: auto;
    overflow-block: scroll;
}

.List p {
    margin: 0;
}

.Closed .ListWrapper {
    display: none;
}

.ListItem {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    box-sizing: content-box;
}
.ListItem label {
    margin-right: 10px;
}

.ListItem:hover {
    background-color: var(--lawmonitor-gray-200);
}

.ListItem:active {
    background-color: var(--lawmonitor-gray-300);
}

.ListItem:hover:first-child {
    border-top-left-radius: 6px;
}

.ListItem:hover:last-child {
    border-bottom-left-radius: 6px;
}

/*Scrollbar Styling*/
.List::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 10px;
}

.List::-webkit-scrollbar {
    width: 12px;
}

.List::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgrey;
    border-radius: 10px;
}

.List::-webkit-scrollbar-thumb:hover {
    background: #bcbcbc;
}
