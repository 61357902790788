.Show {
    top:0;
}

.Filterbar {
    transition: all 0.5s;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: 24px;
    background-color: var(--lawmonitor-gray-100);
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}
.Search {
    height: 56px;
    display: flex;
    align-items: center;
    margin-right: 24px;
    flex: 1;
    max-width: 290px;
}
.FlexWrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    height: 100%;
}
.Dropdowns {
    height: 56px;
    display: flex;
    align-items: center;
    margin-right: 24px;
}
.Dropdowns > div:first-child {
    margin-right: 24px;
}
.Options {
    display: flex;
    align-items: center;
    height: 56px;
}
