:root {
    --lawmonitor-gray-100: #f8f8f8; /* UI Light gray */
    --lawmonitor-gray-200: #efefef; /* UI Focus gray */
    --lawmonitor-gray-300: #ededed; /* UI Border gray */
    --lawmonitor-gray-400: #909090; /* Type & Icon discreet gray */
    --lawmonitor-gray-500: #484848; /* Type & icon main gray */

    --lawmonitor-table-selected-blue-100: #e4f4ff; /* Light blue with more contrast to white */

    --lawmonitor-blue-100: #f2faff; /* Light blue */
    --lawmonitor-blue-200: #009de0; /* Ramboll blue */
    --lawmonitor-blue-300: #47b1e6; /* Ramboll blue faded */

    --lawmonitor-green: #00a300;

    --lawmonitor-red: #c63418;

    --lawmonitor-purple-100: #8982fc;
    --lawmonitor-purple-200: #6c63fb;

    --lawmonitor-lawtype-env: #2CAF2C;
    --lawmonitor-lawtype-workenv: #199DE0;
    --lawmonitor-lawtype-property: #90480A;
    --lawmonitor-lawtype-food: #E07C19;
    --lawmonitor-lawtype-county: #337629;
    --lawmonitor-lawtype-misc: #6C63FB;
    --lawmonitor-lawtype-customlaw: #CE46B9;
}
