.Options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.Options button:last-of-type {
    margin-left: 16px;
}
