.Modal {
    min-width: 662px;
    left: 77%;
    top: 50%;
}

.Modal > h4 {
    word-break: break-all;
}

.Content {
    width: 100%;
}

.Editor button {
    padding: 0
}

.Editor {
    padding-bottom: 20px;
    height: 400px;
}

.Options {
    width: 100%;
    display: flex;
}

.Options button:first-of-type {
    margin-left: auto;
}

.Options button:last-of-type {
    margin-left: 16px;
}

@media screen and (max-width: 1500px) {
    .Modal {
        left: 70%;
    }
}
@media screen and (max-width: 1200px) {
    .Modal {
        left: 50%;
    }
}