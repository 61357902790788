.Container {
    width: 100%;
    display: flex;
    align-items: center;
}

.Stacked {
    flex-direction: column;
}
.Stacked .Text {
    margin-left: auto;
    margin-right: 0;
    color: var(--lawmonitor-gray-500);
    margin-bottom: 2px;
    font-size: 12px;
}

.Text {
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 8px;
}

.Bar {
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    background-color: #e0e0e0;
}

.Progress {
    background-color: var(--lawmonitor-blue-200);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
