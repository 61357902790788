.HtmlCell {
    height: 75px;
    font-size: 12px;
    line-height: 15px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.HtmlCell > * {
    margin: 0;
}
