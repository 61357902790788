.RevisionQuestionItem {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}

.Checkbox {
    display: flex;
    align-items: center;
    margin-right: 22px;
}

.Question {
    font-size: 12px;
    margin: 0;
    flex: 1;
    color: var(--lawmonitor-gray-500);
}
.Question.Disabled {
    color: var(--lawmonitor-gray-400);
}

.RevisionQuestionItem textarea {
    flex: 1;
    height: 100%;
    resize: none;
    border: none;
    font-size: 12px;
    font-style: italic;
    color: var(--lawmonitor-gray-500);
}
.RevisionQuestionItem > img {
    cursor: pointer;
    margin-left: 8px;
    width: 18px;
    height: 18px;
}
.RevisionQuestionItem > img.Disabled {
    pointer-events: none;
    opacity: 0.6;
}

.RevisionQuestionItem > img.NotificationIcon {
    cursor: auto;
}
