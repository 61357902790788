.EmailNotificationsToaster {
    position: absolute;
    right: 284px;
    bottom: 0;
    height: 56px;
    width: 482px;
    padding: 10px 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: var(--lawmonitor-blue-200);
    justify-content: space-between;
}

.TextContainer h6,
.TextContainer p {
    font-size: 12px;
    margin: 0;
}

.Buttons .CancelButton {
    margin-right: 12px;
    background-color: var(--lawmonitor-blue-300);
}
.Buttons button {
    padding: 4px 22px;
    font-size: 12px;
}
