.ContractPage > div {
    max-height: 0;
}

.ContractPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--lawmonitor-gray-500);
    padding-left: 24px;
    margin-bottom: 20px;
    overflow-y: auto;
}

.CompanyDocuments {
    width: 800px;
}

.ContractHeader {
    font-size: 18px;
}

.Contract {
    padding-left: 24px;
    padding-right: 24px;
    width: 800px;
    margin-bottom: 39px;
    background-color: var(--lawmonitor-gray-100);
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-300);
}

.Contract table {
    width: 300px;
}

.Contract h4 {
    font-size: 14px;
}