.OptionButton {
    background-color: #ffffff;
    border-radius: 8px;
    width: 24px;
    height: 24px;
    padding: 1px !important;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 8px;
}
.OptionButton:hover {
    background-color: var(--lawmonitor-gray-300);
}
.OptionButton.Active {
    background-color: var(--lawmonitor-blue-200);
}
.OptionButton svg {
    width: 100%;
    height: 100%;
}
.OptionButton.Active path {
    fill: #ffffff;
}
.OptionButton.Active line {
    stroke: #ffffff;
}
