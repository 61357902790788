.Toggle {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 30px;
}

.Label {
    position: relative;
    display: inline-block;
    padding-left: 32px;
    height: 16px;
    width: 32px;
}

.Label input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
}

.Title {
    padding-left: 10px;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
}

.Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--lawmonitor-gray-400);
    border-radius: 32px;
    height: 16px;
    width: 32px;
    margin-top: auto;
    margin-bottom: auto;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.Slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
}

input:checked + .Slider {
    background-color: var(--lawmonitor-blue-200);
}

input:focus + .Slider {
    box-shadow: 0 0 1px var(--lawmonitor-blue-200);
}

input:checked + .Slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
