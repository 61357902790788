.Modal {
    max-width: 555px;
    width: 100%;
}
.Modal a {
    color: var(--lawmonitor-blue-200);
}
.Buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.ErrorIcon {
    position: absolute;
    top: 24px;
    right: 32px;
}
