.Wrapper {
    height: 100%;
}

.Table {
    font-size: 12px;
    color: var(--lawmonitor-gray-400);
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}

.Table .THead,
.Table tbody tr {
    display: table;
    table-layout: fixed;
}

.Table .THead {
    /* head takes the height it requires,
    and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: 100%;
}

.Table .THead .TR .TH {
    font-weight: normal;
    padding: 8px 24px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    color: var(--lawmonitor-gray-500);
}

.Table .THead .TR .TH > div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Table .THead .TR .TH.Dense {
    padding: 8px 18px;
}

.Table .TBody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    border-top: 1px solid var(--lawmonitor-gray-300);
}

.Table .TBody .TR {
    width: 100%;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
}

.Table .TBody .TR .TD {
    padding: 22px 24px;
    cursor: pointer;
    vertical-align: middle;
    color: var(--lawmonitor-gray-500);
}

.Table .TBody .TR .TD.Dense {
    padding: 11px 18px;
    display: flex;
    align-items: center;
    color: var(--lawmonitor-gray-500);
}

.Table .TBody .TR:hover {
    background-color: var(--lawmonitor-gray-100);
}

.Table .TBody .TR.Selected {
    background-color: var(--lawmonitor-table-selected-blue-100);
}

.SearchBar {
    background-color: var(--lawmonitor-gray-200);
}

.ScrollbarSpacing {
    width: 16px;
}

.CheckboxSpacing {
    width: 64px;
}

.ExpanderSpacing {
    width: 42px;
}

.ColumnSearchField {
    width: 100%;
    max-width: 144px;
    height: 24px;
    border-radius: 6px;
    font-size: 12px;
    padding: 4px 8px;
    border: none;
    outline: none;
}

.ColumnSearchField::placeholder {
    color: var(--lawmonitor-gray-400);
    opacity: 1;
}

.SortDesc,
.SortAsc,
.SortNone {
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.SortAsc path,
.SortDesc path {
    fill: var(--lawmonitor-gray-400);
}

.SortDesc svg {
    transform: rotate(270deg);
}

.SortAsc svg {
    transform: rotate(90deg);
}

.SortNone svg {
    width: 6px;
}

.CheckboxCell {
    padding: 0;
    border-right: 1px solid var(--lawmonitor-gray-200);
}

.CheckboxCell > * {
    min-width: 16px;
}

.LawCell,
.ChangeCell {
    min-width: 250px !important;
    max-width: 400px !important;
}

.LastNewLaw {
    height: 120px !important;
    margin-bottom: 40px;
}

.MenuCell {
    max-width: 64px !important;
    padding: 18px 20px !important;
}

.OpenButtonCell {
    max-width: 108px;
    padding-right: 8px !important;
}

.DateCell {
    max-width: 130px !important;
}

.LastNewLaw::after {
    content: '';
    position: absolute;
    top: 120px;
    left: 0;
    height: 40px;
    width: 100%;
    background-color: var(--lawmonitor-gray-300);
    pointer-events: none;
}

.TD.ExternalUsersCell > div {
    display: flex;
    justify-content: center;
}

.ExternalUsersCell {
    max-width: 160px !important;
}

:global(.ReactVirtualized__Grid__innerScrollContainer) {
    min-height: 100%;
}
