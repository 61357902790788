.Modal {
    left: 80%;
    top: 30%;
}

.Options {
    margin-top: 24px;
    margin-left: auto;
}
.Options button:last-of-type {
    margin-left: 16px;
}

@media screen and (max-width: 1200px) {
    .Modal {
        left: 50%;
    }
}
