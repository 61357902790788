.Wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.5);
}

.LoadingCard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 290px;
    height: 90px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LoadingCard h6 {
    margin-top: 0;
    margin-bottom: 16px;
    color: var(--lawmonitor-gray-400);
    font-size: 14px;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .LoadingCard {
        border: 1px solid var(--lawmonitor-gray-300);
        box-shadow: 0px 3px 6px var(--lawmonitor-gray-300);
    }
}
