.Invoicing {
    height: 100%;
    overflow-y: auto;
}

.List {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.TableWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.ToggleCell {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6px;
}
.ToggleCell .OpenAccordion {
    transform: rotate(90deg);
}

.CheckboxCell, .InvoiceStatusImg {
    margin-top: 26px;
}