.CompanyUsersModal {
    overflow: initial;
}

.Content {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    width: 384px;
}
.Content :global .dropdownHeader {
    width: 100%;
}

.Buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}
.Buttons button:last-of-type {
    margin-left: 16px;
}
