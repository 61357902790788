.Toast {
    position: fixed;
    bottom: 0;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-bottom 0.7s;
    height: 56px;
    width: 350px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    border-radius: 6px 6px 0 0;
    padding: 14px 24px;
    z-index: 5000;
}
.Toast.ACCEPTED {
    background-color: var(--lawmonitor-blue-200);
    right: 32px;
}
.Toast.DELEGATED {
    background-color: var(--lawmonitor-purple-200);
    right: 496px;
}

.Title {
    font-size: 12px;
    margin: 0;
}

.Text {
    font-size: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Close {
    position: absolute;
    top: 8px;
    right: 8px;
    filter: invert(1) brightness(2);
    cursor: pointer;
    user-select: none;
}

@keyframes toast-in-bottom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateX(0);
    }
}
