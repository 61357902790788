.Content {
    height: 100%;
    padding: 8px 24px 16px 24px;
    overflow-y: auto;
}

.DetachedCopyHeader {
    display: flex;
    align-items: center;
    background-color: var(--lawmonitor-blue-200);
    height: 40px;
    padding: 14px 16px;
    font-size: 12px;
    color: #ffffff;
}
