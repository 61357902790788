.Modal {
    width: 100%;
    max-width: 600px;
    max-height: 80vh;
    padding: 0;
    overflow-y: auto;
}
.Modal > h4 {
    margin-top: 24px;
    margin-left: 32px;
    margin-bottom: 6px;
}

.Container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
    padding: 24px 32px;
}
.Content > h4 {
    margin: 0;
}
.Content > p {
    margin-top: 0;
    margin-bottom: 8px;
}

.Section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.NameInput {
    max-width: 100%;
}

.DropdownInput {
    max-width: 250px;
    width: 100%;
}
.DropdownInput > p {
    margin-top: 0;
    margin-bottom: 6px;
}
.DropdownInput > div,
.DropdownInput > div > div {
    width: 100%;
}

.FileUpload {
    display: flex;
}
.FileUpload > button {
    padding: 4px 18px 4px 18px;
    margin-right: 16px;
    font-size: 12px;
}
.FileInput {
    display: none;
}

.FileBadge {
    border: 1px solid var(--lawmonitor-blue-200);
    background-color: var(--lawmonitor-blue-100);
    color: var(--lawmonitor-blue-200);
    border-radius: 6px;
    padding: 4px 4px 4px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    overflow: hidden;
}
.FileBadge > p {
    margin: 0;
    margin-right: 16px;
    font-size: 12px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.FileBadge > svg {
    cursor: pointer;
}
.FileBadge > svg > g > path:last-of-type {
    fill: var(--lawmonitor-blue-200);
}
.FileError,
.DateError {
    display: flex;
    align-items: center;
}
.FileError > img,
.DateError > img {
    margin-right: 8px;
}
.FileError > p,
.DateError > p {
    margin: 0;
    font-size: 12px;
    color: var(--lawmonitor-red);
}
.DateError {
    margin-top: 8px;
}

.DatePickerContainer {
    display: flex;
    align-items: center;
    z-index: 2;
}
.DatePickerContainer > svg {
    margin-left: 8px;
    cursor: pointer;
}
.DatePicker {
    height: 24px;
    width: 160px;
    border: 1px solid var(--lawmonitor-gray-500);
    color: var(--lawmonitor-gray-500);
    border-radius: 6px;
    padding-left: 10px;
    font-size: 12px;
    cursor: pointer;
}
.DatePickerCalendar button {
    padding: 0;
}

.AccordionContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
    padding: 24px 32px;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0 32px 24px 32px;
}
.Buttons > button {
    padding: 4px 18px 4px 18px;
    margin-left: 16px;
    font-size: 12px;
}

.RemoveButton {
    flex: 1;
}

.Wrapper {
    position: relative;
    top: unset;
    bottom: 300px;
    height: 100%;
}