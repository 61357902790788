.Modal {
    max-width: 1200px;
}

.Content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Warning {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
}

.Warning img {
    width: 30px;
}

.SubCopiesWarning {
    font-style: italic;
}

.CompanyList h4 {
    margin-bottom: 0;
}
.CompanyList ul {
    padding-left: 24px;
    list-style: none;
    max-height: 300px;
    overflow-y: auto;
}

.ButtonRow1 {
    margin-top: 16px;
    margin-left: auto;
}

.ButtonRow1 button:last-of-type {
    margin-left: 16px;
}

.ButtonRow2 {
    margin-top: 16px;
    margin-left: auto;
}

.ButtonRow2 button:last-of-type {
    margin-left: 16px;
}
