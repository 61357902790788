.Img {
    width: 35vh;
    margin-top:3vh;
    filter: drop-shadow(1px 1px 1px lightgray);

}

.Container {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
