.Modal {
    width: 100%;
}

.Modal > div {
    flex-direction: column;
}

.Content {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
}
.Content > div {
    max-width: 100%;
}

.Buttons button {
    padding: 4px 32px 5px 32px!important;
}

.Buttons {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.Buttons button:last-of-type {
    margin-left: 12px;
}

.RemoveButton {
    margin-right: auto;
}
