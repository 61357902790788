.Dropdown {
    position: relative;
    font-size: 12px;
    cursor: pointer;
}

.Header {
    width: 184px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-500);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    z-index: 1000;
    user-select: none;
    background-color: #ffffff;
}

.Title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
}

.Header .Icon {
    transform: rotate(90deg);
}

.Reversed .Header .Icon {
    transform: rotate(270deg);
}

.Closed .Header .Icon {
    transform: rotate(0deg);
}

.List {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 26px;
    left: 0;
    min-width: 184px;
    background-color: #ffffff;
    border: 1px solid var(--lawmonitor-gray-500);
    border-radius: 6px;
    z-index: 1001;
}

.Reversed .List {
    top: auto;
    bottom: 26px;
    max-height: 330px;
    overflow-y: auto;
}

.Closed .ListWrapper,
.Closed .List {
    display: none;
}

.ListItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    white-space: nowrap;
}
.ListItemText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ListItemText.Ungrouped {
    font-style: italic;
}
.ListItemText span {
    width: 100%;
}

.ListItemText span > p {
    text-overflow: ellipsis;
    margin: 0;
    display: inline-flex;
}
.ListItemText label {
    margin-right: 10px;
}
.List .Icon {
    margin-left: 10px;
    transform: rotate(90deg);
}
.List .Open .Icon {
    transform: rotate(0deg);
}

.SubListWrapper {
    position: relative;
    margin-bottom: auto;
}
.SubList {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: calc(100% + 1px);
    min-width: 184px;
    background-color: #ffffff;
    border: 1px solid var(--lawmonitor-gray-500);
    border-radius: 6px;
    z-index: 700;
}
.SubList label {
    margin-left: 10px;
}
