.SendExternalMailConfirmModal {

}

.Buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.Buttons button {
    color: white!important;
}

.Modal > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
.Modal > div > button:last-of-type {
    margin-left: 16px;
}
