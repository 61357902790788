.Container {
    background-color: white;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    margin-left: 23px;
    margin-right: 33px;
    padding-top: 13px;
    padding-bottom: 13px;
}

Table {
    width: 100%;
}

.Title {
    size: 12px;
    font-weight: bold;
    width: 370px;
}

.Price {
    text-align: right;
}

.Description {
    size: 12px;
    width: 370px;
}

.TotalPrice {
    background-color: white;
    margin-left: 23px;
    margin-right: 33px;
    cursor: pointer;
    padding-top: 13px;
    padding-bottom: 13px;
    text-align: right;
    font-weight: bold;
}