.Modal {
    width: 100%;
}

.Modal > h4 {
    word-break: break-all;
}

.Content {
    width: 100%;
}

.Content p:last-of-type {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: bold;
}

.Options {
    width: 100%;
    display: flex;
}

.Options button:first-of-type {
    margin-left: auto;
}

.Options button:last-of-type {
    margin-left: 16px;
}
