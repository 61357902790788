.EditFullViewFooter {
    min-height: 106px;
    height: 106px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    color: #ffffff;
    padding: 10px 24px 16px 24px;
    font-size: 12px;
    background-color: var(--lawmonitor-blue-200);
}

.Title,
.Text {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Buttons {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
}
.Buttons button {
    color: var(--lawmonitor-blue-200);
    border: none;
}

.RED {
    background-color: var(--lawmonitor-red);
}
.RED button {
    color: var(--lawmonitor-red);
}

.GREEN {
    background-color: var(--lawmonitor-green);
}
.GREEN button {
    color: var(--lawmonitor-green);
}

.MobileButtons {
    display: flex;
    justify-content: space-evenly;
    padding: 24px 24px 16px 32px;
}

.MobileButtons button {
    padding: 5px 0;
    font-size: 14px;
    width: 50%;
}

.MobileButtons button:last-of-type {
    margin-left: 16px;
}