.KeywordEditor {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 26px;
}
.Header h5 {
    margin: 0;
}

.List {
    flex: 1;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    border-radius: 6px;
    font-size: 14px;
    color: var(--lawmonitor-gray-500);
    border: 1px solid var(--lawmonitor-gray-300);
}
.List li {
    padding: 4px 28px;
    cursor: pointer;
}
.List li:hover {
    background-color: var(--lawmonitor-gray-100);
}
.List li label {
    pointer-events: none;
    margin-right: 10px;
}
