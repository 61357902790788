.EmailNotifications {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Container {
    padding: 24px;
}

.Title {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
}

.Box {
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    max-width: 600px;
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    padding: 16px 24px 32px 24px;
}
.BoxTitle {
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    margin-top: 0;
    margin-bottom: 16px;
}

.Box > p {
    margin-bottom: 24px;
}

.ToggleContainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
}
.ToggleContainer > p {
    margin: 0;
    margin-right: 20px;
}
.Toggle {
    width: auto;
}

.Filterbar {
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px 20px 24px;
    background-color: var(--lawmonitor-gray-100);
}

.FilterText {
    padding-left: 24px;
    padding-bottom: 12px;
    font-size: 12px;
}

.Options {
    display: flex;
}
/*.Options > label:first-of-type {*/
/*    margin-right: 50px;*/
/*}*/
.Options > label {
    margin-top: 0;
    margin-right: 50px;
}

.Table {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
}
.Table.Disabled {
    opacity: 0.6;
    pointer-events: none;
}

.TableHeader {
    display: flex;
    align-items: center;
    background-color: var(--lawmonitor-gray-100);
    border-top: 1px solid var(--lawmonitor-gray-200);
}

.ToggleCell {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6px;
}
.ToggleCell .ExpandedIcon {
    transform: rotate(90deg);
}
.ToggleCell.Disabled {
    opacity: 0.6;
}
