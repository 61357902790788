
.Container {
    display: flex;
    align-items: center;
    height: 22px;
}

/*.CopyContainer {*/
/*    text-align: left;*/
/*    line-height: 1;*/
/*    margin-top: 2px;*/
/*    margin-bottom: 0;*/
/*    display: inline-flex;*/
/*}*/

/*.CopyContainer img {*/
/*    margin-right: 2px;*/
/*    width: 36px;*/
/*    margin-top: 4px;*/
/*}*/

/*.CopyContainer p {*/
/*    width: 58px;*/
/*    text-align: left;*/
/*    line-height: 1;*/
/*    margin-top: 9px;*/
/*    margin-bottom: 0;*/
/*}*/

.CopyContainer:hover {
    cursor: pointer;
    opacity: 0.8;
}

.CopyContainer:active {
    opacity: 1;
}

.LinkContainer:hover {
    cursor: pointer;
    opacity: 0.8;
}

.LinkContainer:active {
    opacity: 1;
}

.LinkContainer {
    display: inline-flex;
}

.Link {
    color: white;
    display: contents;
}

.Link img {
    margin-right: 8px;
    width: 36px;
    margin-top: 4px;
}

.Link p {
    width: 148px;
    text-align: left;
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 0;
}