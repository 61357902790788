.List {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.ConfirmRemoveModalButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}
.ConfirmRemoveModalButtons button:last-of-type {
    margin-left: 16px;
}

.ExportButton {
    font-size: 12px;
    padding: 4px 28px;
    margin-right: 16px;
}