.UserInfo > div {
    max-height: 0;
}

.UserInfo {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
    margin-bottom: 20px;
    overflow-y: auto;
}

.Title {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
}

.Box {
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    max-width: 600px;
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    margin-bottom: 100px;
}
.BoxSection {
    padding: 24px 32px 0 32px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
}
.BoxTitle {
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    margin-top: 0;
    margin-bottom: 18px;
}
.BoxRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.DropdownInput {
    max-width: 250px;
    width: 100%;
}
.DropdownInput > p {
    margin-top: 0;
    margin-bottom: 6px;
}
.DropdownInput > div,
.DropdownInput > div > div {
    width: 100%;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 16px 32px 24px 0;
}
.Buttons button {
    padding: 4px 24px 5px 24px;
    font-size: 12px;
}
.Buttons button:last-of-type {
    margin-left: 16px;
}

.QrContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 32px;
}

.QrButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 150px;
    align-items: center;
    margin: 10px 0;
}

.Counter {
    margin:0;
    font-size: 14px;
}

.Image {
    width: 250px;
    margin-top: 21px;
    margin-bottom: 21px;
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.1);
}
