.LoginBackground {
    background-color: var(--lawmonitor-gray-100);
    flex : 1;
    overflow: auto;
}

.Login {
    width: 488px;
    height: 624px;
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    float: none;
    background-color: white;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 6px;
}

.Header {
    margin-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 120px;
}

.Header p {
    font-family: var(--lawmonitor-proxima-soft);
    color: var(--lawmonitor-blue-200);
    text-align: center;
    font-size: 29px;
    line-height: 1.1;
    width: 80%;
    margin: 0 auto;
}

.InputGroup {
    font-size: 14px;
    width: 100%;
}

.InputGroup input {
    border: 1px solid var(--lawmonitor-gray-300);
    height: 24px;
    border-radius: 6px;
}

.Input {
    margin-left: auto;
    margin-right: auto;
}

.Login small {
    color: red;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
}

.ButtonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ButtonGroup > button {
    margin-bottom: 16px;
}
.LoginBtn {
    width: 208px;
    border-radius: 30px;
    font-size: 19px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
}

.PwdBtn {
    font-size: 13px;
    color: var(--lawmonitor-gray-400);
    margin-top: 7px;
    margin-left: auto;
    margin-right: auto;
}

.ErrorDiv {
    height: 50px;
}

.Error {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
    font-size: 10px;
    color: var(--lawmonitor-red);
}

@media screen and (max-width: 600px) {

    .Login{
        width: 100%;
        box-shadow: none;
        margin-top: 0;
        height: 80%;
    }
    .LoginBackground{
        background-color: white;
    }

    .Header {
        padding-top: 10vh;
        margin-bottom: 10vh;
        filter: drop-shadow(1px 1px 1px lightgray);

    }

    .Input {
        filter: drop-shadow(1px 1px 1px lightgray);
        font-size: 16px;
    }

    .LoginBtn{
        filter: drop-shadow(1px 1px 1px lightgray);
    }
}
