.ContextMenuReset {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    border-top: 1px solid var(--lawmonitor-gray-300);
    overflow: hidden;
}

.Open .Info {
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 18px;
}

.Info {
    display: flex;
    align-items: center;
}

.Info p {
    margin: 0;
    margin-left: 8px;
    font-size: 10px;
}

.ContextMenuReset button {
    margin-bottom: 6px;
}

.Filtered path {
    fill: red;
}
