.Modal {
    width: 100%;
    overflow-y: unset;
}

.Content {
    width: 100%;
}

.Label {
    margin-top: 16px;
    margin-bottom: 8px;
}

.Input {
    border: 1px solid var(--lawmonitor-gray-500);
    border-radius: 6px;
    width: 100%;
    height: 24px;
    background-color: #ffffff;
    overflow: hidden;
    outline: none;
    padding: 4px 6px;
}
.Input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--lawmonitor-gray-400);
    opacity: 1; /* Firefox */
}
.Input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--lawmonitor-gray-400);
}
.Input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--lawmonitor-gray-400);
}

.Options {
    display: flex;
    justify-content: flex-end;
    margin-top: 37px;
}
.Options button:last-of-type {
    margin-left: 16px;
}
