.LawListEditor {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 26px;
}
.Header h5 {
    margin: 0;
}

.List {
    flex: 1;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    border-radius: 6px;
    font-size: 14px;
    color: var(--lawmonitor-gray-500);
    border: 1px solid var(--lawmonitor-gray-300);
}

.LawListGroup {
    padding: 4px 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.LawListGroup:hover,
.SubList li:hover {
    background-color: var(--lawmonitor-gray-100);
}
.LawListGroup.Ungrouped {
    font-style: italic;
}
.LawListGroup label {
    margin-right: 10px;
}
.LawListGroup span {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 6px;
}

.Icon {
    margin-left: auto;
}
.Open .Icon {
    transform: rotate(90deg);
}

.SubList {
    margin: 0;
    padding: 0;
    list-style: none;
}
.SubList li {
    padding: 4px 28px 4px 48px;
    cursor: pointer;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.SubList li label {
    pointer-events: none;
    margin-right: 10px;
}
