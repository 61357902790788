.RevisionQuestionsCell {
  height: 100%;
  overflow: hidden;
}

.RevisionQuestion {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Title {
  font-weight: bold;
}