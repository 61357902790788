.DetachedCopyFullViewHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--lawmonitor-blue-200);
    height: 40px;
    padding: 14px 16px;
    font-size: 12px;
    color: #ffffff;
}

.DetachedCopyFullViewHeader button {
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    color: var(--lawmonitor-blue-200);
}
