.UserMenu {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 16px;
    margin-left: auto;
}

.Icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9000;
    filter: drop-shadow(1px 1px 1px lightgray);

}

.Menu {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    color: #3e3e3c;
    background-color: var(--lawmonitor-gray-100);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    min-width: 220px;
    z-index: 8001;
}

.Header {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 55px;
    margin-right: 48px;
    padding-left: 16px;
    padding-right: 30px;
}
.FullName {
    font-size: 15px;
}
.CompanyName {
    font-size: 10px;
}

.List {
    border-top: 1px solid var(--lawmonitor-gray-300);
}

.List,
.BottomList {
    padding-top: 16px;
    padding-bottom: 24px;
}

.List > *,
.BottomList > * {
    display: block;
    color: #3e3e3c;
    text-decoration: none;
    font-size: 14px;
    padding: 8px 24px;
    cursor: pointer;
}

.BottomList {
    margin-top: auto;
}

.Footer {
    display: flex;
    padding: 16px 24px 24px 24px;
    background-color: var(--lawmonitor-blue-100);
    border-top: 1px solid var(--lawmonitor-gray-300);
}
.Footer p {
    font-size: 12px;
    margin: 0;
    color: var(--lawmonitor-blue-200);
    flex: 1;
}

.ViewMode {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
