.App {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
}
