.Topbar {
    height: 54px;
    width: 100%;
    background-color: var(--lawmonitor-blue-100);
    display: flex;
    align-items: center;
    padding: 22px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}
.Title {
    color: var(--lawmonitor-gray-500);
    font-size: 14px;
    margin: 0;
    margin-left: 22px;
    min-width: 75px;
}
.BackIcon {
    cursor: pointer;
}

.Children {
    margin-left: auto;
}
