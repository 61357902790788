.Backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--lawmonitor-gray-100);
    opacity: 0.9;
    z-index: 2000;
}

.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    opacity: 1;
    z-index: 2100;
    padding: 24px 32px;
    max-width: 450px;
    max-height: calc(100% - 64px);
    overflow-y: auto;
}

.Title {
    margin-top: 0;
    margin-bottom: 4px;
}

.SubTitle {
    margin-top: 0;
    margin-bottom: 4px;
}

.Text {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 12px;
    color: var(--lawmonitor-gray-400);
}

.Options {
    width: 100%;
    display: flex;
    align-items: center;
}
.Options button {
    padding: 4px 32px 5px 32px;
    font-size: 12px;
}
