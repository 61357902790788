.Help {
    display: flex;
    height: 100%;
    flex: 1;
}

.Menu {
    width: 240px;
    padding-top: 8px;
    background-color: var(--lawmonitor-gray-100);
    border-right: 1px solid var(--lawmonitor-gray-300);
}

.Link {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    text-decoration: none;
}

.Link .Header {
    display: flex;
    align-items: center;
    border-left-width: 6px;
    border-left-style: solid;
    border-left-color: transparent;
    padding: 8px 10px;
}

.Link .Header img {
    margin-right: 8px;
}

.Link .Header p {
    margin: 0;
}

.Link.Active .Header {
    border-left-color: var(--lawmonitor-blue-200);
    background-color: var(--lawmonitor-blue-100);
}

.Content {
    flex: 1;
    height: 100%;
    overflow-y: auto;
}
