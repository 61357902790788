.EditOldWarningModal {
    max-width: 650px;
}

.Buttons {
    width: 100%;
    display: flex;
    margin-top: 24px;
}
.Buttons button:first-of-type {
    margin-right: auto;
}
.Buttons button:last-of-type {
    margin-left: 16px;
}
