.LawCellWrapper{
    margin-left: 1vh;
    margin-right: 2vh;
    text-align: left;
    color: var(--lawmonitor-gray-500);
}

.LawDescription {
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.SubId {
    font-size: 14px;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.StatusIconContainer {
    display: flex;
    width: 140px;
}
