.Search {
    display: flex;
    border: 1px solid var(--lawmonitor-gray-500);
    border-radius: 6px;
    max-width: 290px;
    min-width: 184px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    overflow: hidden;
}
.Icon {
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    padding: 2px 4px;
    padding-right: 6px;
    cursor: pointer;
}
.Input {
    width: 100%;
    outline: none;
    border: none;
}
.Input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--lawmonitor-gray-400);
    opacity: 1; /* Firefox */
}

.Input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--lawmonitor-gray-400);
}

.Input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--lawmonitor-gray-400);
}
