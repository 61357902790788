.DetachedCopies {
    padding: 24px;
}

.Title {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
}

.Information {
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    padding: 18px 24px 24px 24px;
    max-width: 592px;
    font-size: 12px;
}
.Information h6 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 12px;
}

.Warning {
    margin-top: 16px;
    display: flex;
}
.Warning p {
    margin: 0;
}
.Warning img {
    height: 16px;
    width: 16px;
    margin-right: 6px;
}

.Information .Buttons {
    display: flex;
    justify-content: flex-end;
}
.Information .Buttons button {
    margin-top: 16px;
    padding: 4px 20px;
    font-size: 12px;
}
.Information .Buttons button:last-of-type {
    margin-left: 16px;
}
