.DelegateEditor {
    position: absolute;
    top: 0;
    left: 0;
    width: max(50%, calc(100% - 696px));
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow-y: auto;
    border-left: 1px solid var(--lawmonitor-gray-300);
    border-right: 1px solid var(--lawmonitor-gray-300);
    z-index: 1100;
}

.CloseBar {
    height: 24px;
    min-width: 100%;
    padding: 8px;
    background-color: var(--lawmonitor-gray-300);
    display: flex;
    align-items: center;
}
.CloseBar svg {
    cursor: pointer;
}

.CommentSection,
.AssessmentSection {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    overflow: hidden;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}
.AssessmentSection {
    flex: 1;
}

.AssessorEditor {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.AssessorEditor .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 26px;
}
.AssessorEditor .Header h5 {
    margin: 0;
}

.AssessorEditor .List {
    flex: 1;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 20px;
    border-radius: 6px;
    font-size: 14px;
    color: var(--lawmonitor-gray-500);
    border: 1px solid var(--lawmonitor-gray-300);
}
.AssessorEditor .List li {
    padding: 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.AssessorEditor .List li label {
    pointer-events: none;
    margin-right: 10px;
}
.AssessmentSection .List li .FullName,
.AssessmentSection .List li .Email {
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.AssessmentSection .List li .FullName {
    font-weight: bold;
}
.CommentSection {
    height: 180px;
}
.CommentSection .TextEditor {
    height: 100%;
}

.Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;
    padding: 24px;
    margin-bottom: 8px;
}
.Footer .SelectUsers {
    margin-right: auto;
}
.Footer button {
    padding: 4px 24px 5px 24px;
    font-size: 12px;
}
.Footer button:last-of-type {
    margin-left: 16px;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .DelegateEditor {
        width: 50%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .DelegateEditor {
        width: 50%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .DelegateEditor {
        width: 50%;
    }
}
