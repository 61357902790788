.DenseCell {
    color: var(--lawmonitor-gray-500);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.DenseCell > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.NameColumn {
    font-weight: bold;
}

.NameColumn.PrimaryCompany {
    color: var(--lawmonitor-blue-200);
}

@supports (-webkit-line-clamp: 1) {
    .MultiRow > span {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}

@supports not (-webkit-line-clamp: 1) {
    .MultiRow > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
