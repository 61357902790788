.VideoPlayer {
    position: relative;
    height: 100%;
    width: 100%;
}
.VideoPlayer > img {
    height: 100%;
    cursor: pointer;
    object-fit: cover;
}
.VideoPlayer > video {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
}
.Hidden {
    display: none;
}