.CustomColumns {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--lawmonitor-gray-500);
    padding: 24px;
    overflow-y: auto;
}

.Title {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
}

.InfoBox {
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    padding: 16px 24px;
    max-width: 600px;
    font-size: 12px;
    margin-bottom: 16px;
}
.InfoBoxTitle {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 12px;
}

.ColumnBox {
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    max-width: 600px;
    font-size: 12px;
}
.ColumnBoxHeader {
    display: flex;
    padding: 16px 24px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
}
.ColumnBoxHeader > h6 {
    margin: 0;
    font-size: 12px;
}
.ColumnBoxSection {
    padding-top: 16px;
    padding-bottom: 2px;
    padding-left: 24px;
    padding-right: 16px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.ColumnBoxTitle {
    font-size: 14px;
    margin: 0;
    margin-right: 16px;
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
}
.Input {
    margin-right: 12px;
}
.Input > p {
    margin-top: 2px !important;
}
.ColumnBoxSection > button {
    padding: 4px 20px;
    font-size: 12px;
}
.ColumnBoxFooter {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
    justify-content: flex-end;
}
.ColumnBoxFooter > button {
    padding: 4px 20px;
    font-size: 12px;
    margin-bottom: 8px;
    width: 79px;
}
