.EditLatestSubscriptionModal {
    width: 100%;
    max-width: 600px;
    height: 750px;
    padding: 0;
}
.EditLatestSubscriptionModal > h4 {
    display: none;
}
.EditLatestSubscriptionModal > div {
    height: 100%;
}
.Content {
    height: 100%;
    width: 100%;
}
.Content > div {
    position: initial;
    height: 100%;
    width: 100%;
}
