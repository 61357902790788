.EmptyListView {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 57px;
}

.Icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.Icon > h6,
.Icon > p {
    color: var(--lawmonitor-gray-400);
}

.Icon > h6 {
    font-size: 28px;
    font-family: proxima-soft;
    margin-top: 0;
    margin-bottom: 12px;
}

.Icon > p {
    font-size: 18px;
    font-family: proxima-soft;
    margin-top: 0;
    margin-bottom: 48px;
    white-space: pre-line;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Icon > p,
    .Icon > h6 {
        font-family: local('proxima-soft');
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .Icon > p,
    .Icon > h6 {
        font-family: local('proxima-soft');
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .Icon > p,
    .Icon > h6 {
        font-family: local('proxima-soft');
    }
}
