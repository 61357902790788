.Header {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.Header .Title {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

.Header img {
    margin-right: 10px;
}

.Open .Header img {
    transform: rotate(90deg);
}

.List {
    padding: 0;
    margin: 0 0 8px 24px;
}

.List li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
    list-style: none;
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
}

.List li .ListTitle {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 6px;
    width: 100%;
    overflow: hidden;
    white-space: initial;
}

.List li input {
    pointer-events: none;
}

.HiddenIcon {
    opacity: 0;
}
