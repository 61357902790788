.CompanyLogin {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
}
.List {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.TableWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.DocumentButton {
    font-size: 12px;
    padding: 4px 12px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
}

.LoginButton {
    font-size: 12px;
    padding: 4px 12px;
    max-width: 100%;
}

.ChangeNumber {
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    background-color: rgb(25, 157, 226);
    border-color: transparent;
    border-radius: 25px;
    padding: 4px 16px;
}

.AdminInfoCell {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-height: 3.6em;
    line-height: 1.8em;
    margin-right: 10px;
    width: 100%;
    white-space: nowrap;
}

.AdminInfoCell p {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

@supports (-webkit-line-clamp: 1) {
    .AdminInfoCell p {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}

@supports not (-webkit-line-clamp: 1) {
    .AdminInfoCell p {
        white-space: nowrap;
    }
}

.ToggleCell {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6px;
}
.ToggleCell .OpenAccordion {
    transform: rotate(90deg);
}

.Loading {
    margin-left: 10px;
    border: 3px solid var(--lawmonitor-gray-100);
    border-top: 3px solid var(--lawmonitor-blue-200);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 2s linear infinite;
}

.CompanyLoginModal {
    /*Custom width on CompanyLoginModal*/
    /*min-width: 800px;*/
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 800px){
    .LoginButton {
        padding: 4px 5px;

    }

}
