.ContentWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: scroll;
}

.Title {
    font-family: var(--lawmonitor-proxima-soft);
    text-align: center;
    font-size: 40px;
    width: 80%;
    margin: 40px 0 30px 0;
}

.Image{
    min-width:140px ;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.DesktopImage{
    min-width:100px ;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.SubTitle {
    font-size: 20px;
    font-family: var(--lawmonitor-proxima-soft);
}

.Section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Section:last-child {
    margin-top: 20px;
}
