.Label {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.Label.Disabled {
    opacity: 0.6;
}

.Big.Label {
    padding-left: 16px;
    margin-bottom: 16px;
}

/* Hide the browser's default checkbox */
.Label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    top: -4px;
    left: -4px;
}

/* Create a custom checkbox */
.Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 10px;
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 2px;
}

.Big .Checkmark {
    height: 16px;
    width: 16px;
}

/* On mouse-over, add a grey background color */
.Label:hover input ~ .Checkmark {
    background-color: #eee;
}

/* When the checkbox is checked or indeterminate, add a blue background */
.Label input:checked ~ .Checkmark,
.Label input[data-indeterminate='true'] ~ .Checkmark {
    background-color: #199de2;
    border: 1px solid #199de2;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked or indeterminate */
.Label input:checked ~ .Checkmark:after,
.Label input[data-indeterminate='true'] ~ .Checkmark:after {
    display: block;
}

/* Style the checkmark */
.Label .Checkmark:after {
    left: 2.5px;
    top: 0.5px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
}

.Big.Label .Checkmark:after {
    left: 4px;
    top: 0;
    width: 6px;
    height: 11px;
    border-width: 0 2px 2px 0;
}

/* Style the indeterminate checkmark */
.Label input[data-indeterminate='true'] ~ .Checkmark:after {
    border-width: 0 1px 0 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.Big.Label input[data-indeterminate='true'] ~ .Checkmark:after {
    border-width: 0 2px 0 0;
}
