.InstructionsVideo {
    display: flex;
    flex-direction: column;
    height: 325px;
    width: 400px;
    padding: 16px;
    background-color: var(--lawmonitor-gray-100);
    margin-bottom: 24px;
    margin-right: 24px;
}

.Media {
    max-height: 180px;
    max-width: 320px;
    width: 100%;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
}

.Text {
    width: 100%;
    margin-bottom: 16px;
}

.Text p,
.Text h4 {
    font-size: 12px;
}

.Text p {
    margin: 0;
}
.Text h4 {
    margin-top: 0;
    margin-bottom: 16px;
}
