.CreateDetachedCopiesModal {
    overflow: initial;
    width: 100%;
    transform: translate(-50%, -191px);
}

.Content {
    width: 100%;
    margin-top: 20px;
}

.DropdownInput {
    margin-bottom: 32px;
}
.DropdownInput > p {
    margin-top: 0;
    margin-bottom: 8px;
}
.DropdownInput,
.DropdownInput > div,
.DropdownInput > div > div {
    width: 100%;
}

.Warning {
    display: flex;
    align-items: flex-start;
    margin-bottom: 44px;
}
.Warning img {
    margin-right: 16px;
}
.Warning p {
    margin: 0;
}

.Buttons {
    display: flex;
    justify-content: space-between;
}
.Buttons button:last-of-type {
    /*margin-left: 16px;*/
}
