.Popup {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 16px;
    margin-left: auto;
    font-family: var(--lawmonitor-proxima-soft);
}

.Arrow {
    position: absolute;
    background-color: white;
    box-shadow: -4px 9px 7px rgba(0, 0, 0, 0.2);
    border: solid 1px var(--lawmonitor-gray-400);
    width: 50px;
    height: 50px;
    transform: rotate(45deg);
    border-radius: 5px;
    right: -41px;
    top: 50px;
    z-index: 8000;
}

.ArrowOverlay {
    position: absolute;
    background-color: white;
    width: 48px;
    height: 48px;
    transform: rotate(45deg);
    border-radius: 5px;
    right: -40px;
    top: 51px;
    z-index: 8001;
}


.PopupContent {
    position: absolute;
    top: 63px;
    right: -66px;
    display: flex;
    flex-direction: column;
    color: #3e3e3c;
    background-color: white;
    box-shadow: -6px 4px 8px rgba(0, 0, 0, 0.2);
    border: solid 1px var(--lawmonitor-gray-400);
    min-width: 400px;
    min-height: 400px;
    border-radius: 5px;
    padding: 21px;
    z-index: 8000;
}

.Header {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 26px;
}

.Header h1 {
    font-size: 25px;
    margin: 0;
}

.Text {
    font-size: 17px;
    line-height: 1.3;
    margin: 0;
    color: var(--lawmonitor-gray-400);

}

.Text p > span {
    margin-top: -2px;
    position: fixed;
    margin-left: 3px;
}

.Text p > span > img {
    width: 26px;
}

.Video {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 230px;
}

.Video video {
    width: auto;
}

.Footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 1px;
}

.Footer > button {
    font-size: 12px;
    /*width: 50px;*/
}