.KeywordCell {
    height: 100%;
    overflow: hidden;
}

.KeywordValue {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
