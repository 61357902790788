.NewsFeed {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-200);
    margin-bottom: 24px;
    position: relative;
}

.Header {
    display: flex;
    align-items: center;
    background-color: var(--lawmonitor-gray-100);
    height: 40px;
    padding: 10px 20px;
}
.Header h4 {
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.EmptyContentText {
    display: flex;
    align-items: center;
    margin: 0;
}

.EmptyContentText h4 {
    color: var(--lawmonitor-gray-400);
    font-size: 14px;
    margin: 0;
}

.EmptyContentText img {
    margin-right: 10px;
}

.Content {
    padding: 20px 24px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.Status {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.Status:last-of-type {
    margin-bottom: 0;
}
.Status a {
    color: var(--lawmonitor-gray-500);
    text-decoration: none;
}
.Status a:hover {
    text-decoration: underline;
}
.Status img {
    margin-right: 10px;
}
