.Container {
    position: relative;
    display: inline-block;
    margin-left: 8px;
}

.ColorButton {
    background-color: #ffffff;
    border-radius: 8px;
    width: 31px;
    height: 24px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
}
.ColorButton:hover {
    background-color: var(--lawmonitor-gray-300);
}

.ColorButtonIcon.black :global(.data-color-line) {
    fill: var(--lawmonitor-gray-500);
}
.ColorButtonIcon.red :global(.data-color-line) {
    fill: #c63418;
}
.ColorButtonIcon.green :global(.data-color-line) {
    fill: #00a300;
}

.ColorButtonIcon.Open :global(.a-color-icon),
.ColorButtonIcon:hover :global(.a-color-icon) {
    fill: #f8f7f7;
}
.ColorButtonIcon.Open :global(.b-color-icon),
.ColorButtonIcon:hover :global(.b-color-icon) {
    fill: #efefef;
}

.ColorButtonIcon :global(.a-color-icon),
.ColorButtonIcon :global(.b-color-icon) {
    fill: #ffffff;
}

.ColorButtonIcon {
    width: 100%;
    height: 100%;
}

.ColorList {
    position: absolute;
    top: 24px;
    left: 0;
    margin-top: 2px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--lawmonitor-gray-300);
    padding: 5px;
    z-index: 100;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
}

.ColorItem {
    width: 12px;
    height: 12px;
    border-radius: 1px;
    cursor: pointer;
    margin-right: 4px;
}
.ColorItem:last-of-type {
    margin-right: 0;
}
