.LawTypeBadge {
    display: inline-block;
    font-size: 11px;
    white-space: nowrap;
    padding: 0 14px 1px 14px;
    border-style: solid;
    border-width: 1px;
    border-color: #7e51a5;
    color: #7e51a5;
    text-align: center;
    background-color: #ffffff;
    border-radius: 9px;
    margin-top: 8px;
    max-width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ENV {
    border-color: var(--lawmonitor-lawtype-env);
    color: var(--lawmonitor-lawtype-env);
}

.WORK_ENV {
    border-color: var(--lawmonitor-lawtype-workenv);
    color: var(--lawmonitor-lawtype-workenv);
}

.PROPERTY {
    border-color: var(--lawmonitor-lawtype-property);
    color: var(--lawmonitor-lawtype-property);
}

.FOOD {
    border-color: var(--lawmonitor-lawtype-food);
    color: var(--lawmonitor-lawtype-food);
}

.COUNTY {
    border-color: var(--lawmonitor-lawtype-county);
    color: var(--lawmonitor-lawtype-county);
}

.MISC {
    border-color: var(--lawmonitor-lawtype-misc);
    color: var(--lawmonitor-lawtype-misc);
}

.CUSTOM_LAW {
    border-color: var(--lawmonitor-lawtype-customlaw);
    color: var(--lawmonitor-lawtype-customlaw);
}
