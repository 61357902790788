.Container {
    background-color: white;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    margin-left: 23px;
    margin-right: 33px;
    cursor: pointer;
    padding-top: 13px;
    padding-bottom: 13px;
}

.Container:last-child {
    border-bottom: none;
}

Table {
    width: 100%;
}

.UpperRow {
}

.FullName {
    size: 12px;
    font-weight: bold;
    width: 370px;
}

.LastLogin {
    float: right;
}