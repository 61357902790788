.Container {
    background-color: white;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    margin-left: 23px;
    margin-right: 33px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.Container:last-child {
    border-bottom: none;
}

Table {
    width: 100%;
}

.Row {
    display: flex;
    justify-content: space-between;
}

.FullName {
    size: 12px;
    font-weight: bold;
    width: 370px;
}

.FileNameLink {
    cursor: pointer;
    color: var(--lawmonitor-blue-200);
    word-wrap: break-word;
    max-width: 450px;
}
.FileNameLink .LinkIcon {
    height: 14px;
    width: 14px;
    margin-left: 4px;
}

.RemoveIcon {
    cursor: pointer;
}
