.Container {
    width: min-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.ImagePreview {
    max-width: 80vw;
    max-height: 60vh;
}

.ImageContainer {
    display: flex;
    justify-content: center;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0 0 0;
}

.Buttons button {
    padding: 8px 24px;
    font-size: 15px;
    min-width: 100px;
    margin-left: auto;
}

.Title {
    margin-top: 0;
    margin-bottom: 4px;
}

.ModalTopBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.RemoveIcon {
    width: 30px;
    cursor: pointer;
}

.Buttons button:last-of-type {
    margin-left: 16px;
}

.WarningModel {
    left: 50% !important;
    top: 50% !important;
}

.ImageModal {
    max-width: 90vw;
    max-height: 100vh;
}

.Editor {
    height: 150px;
    word-break: break-word;
}

@media screen and (max-width: 800px) {
    .Container {
        width: 100vw;
    }
    .ImagePreview {
        max-width: 90vw;
        margin-bottom: 5px;
    }

    .ImageContainer {
        display: flex;
        justify-content: center;
    }

    .Buttons {
        justify-content: space-evenly;
    }
    .Buttons button  {
        padding: 5px 24px;
        font-size: 14px;
        width: 50%;
    }

    .ImageModal {
        min-width: 100vw;
        height: 80vh;
        top:55%;
    }

    .WarningModel {
        top:70%
    }
}
