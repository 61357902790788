.ConsultantToaster {
    position: absolute;
    right: 50%;
    bottom: 0;
    height: 56px;
    width: 616px;
    padding: 10px 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: var(--lawmonitor-blue-200);
    transform: translateX(50%);
}
.ConsultantToaster.FullViewOpen {
    right: calc(50% + calc(min(50%, 696px) / 2));
}

.Text {
    display: flex;
    flex-direction: column;
}

.Text h6,
.Text p {
    margin: 0;
    font-size: 12px;
}
.Text h6 span {
    font-weight: normal;
}

.Options {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.Options button:last-of-type {
    margin-left: 24px;
}
.Options button {
    padding: 4px 22px;
    font-size: 12px;
}

@media screen and (max-width: 1500px) {
    .ConsultantToaster.FullViewOpen {
        flex-direction: column;
        align-items: flex-start;
        height: 85px;
        width: 385px;
    }
    .Options {
        margin-top: 10px;
    }
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ConsultantToaster.FullViewOpen {
        right: 75%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .ConsultantToaster.FullViewOpen {
        right: 75%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .ConsultantToaster.FullViewOpen {
        right: 75%;
    }
}
