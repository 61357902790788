.List {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.CreateButton {
    font-size: 12px;
    padding: 4px 28px;
    margin-right: 16px;
}

.List :global .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
}
