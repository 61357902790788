.Modal {
    left: 80%;
    top: 30%;
}

.Modal > h4 {
    margin-bottom: 20px;
}

.Content {
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
}

.Dropdown {
    width: 100%;
}

.Requirements {
    margin-top: 20px;
    margin-bottom: 5px;
}

.Requirements p {
    margin: 0;
}

.FileUpload {
    width: 100%;
}

.FileUpload > button {
    padding: 4px 18px 4px 18px;
    margin-bottom: 17px;
    font-size: 12px;
    width: 100%;
}

.FileInput {
    display: none;
}

.FileBadge {
    border: 1px solid var(--lawmonitor-blue-200);
    background-color: var(--lawmonitor-blue-100);
    color: var(--lawmonitor-blue-200);
    border-radius: 6px;
    padding: 4px 4px 4px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    overflow: hidden;
}

.FileBadge > p {
    margin: 0;
    margin-right: 16px;
    font-size: 12px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.FileBadge > svg {
    cursor: pointer;
}

.FileBadge > svg > g > path:last-of-type {
    fill: var(--lawmonitor-blue-200);
}

.FileError {
    display: inline-flex;
}

.FileError p {
    margin-left: 7px;
}

.Options {
    margin-top: 24px;
    margin-left: auto;
}

.Options button:last-of-type {
    margin-left: 16px;
}

@media screen and (max-width: 1200px) {
    .Modal {
        left: 50%;
    }
}
