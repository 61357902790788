/* Open */
.ContextMenu {
    background-color: var(--lawmonitor-gray-100);
    min-width: 240px;
    max-width: 240px;
    width: 240px;
    transition: all 0.2s ease;
    border-right: 1px solid var(--lawmonitor-gray-300);
    display: flex;
    flex-direction: column;
}
.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    font-size: 10px;
    color: var(--lawmonitor-gray-400);
    white-space: nowrap;
}

.Title {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Toggle {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 10px;
    padding-left: 10px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    box-sizing: content-box;
}

.ContextMenuList {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin: 0;
    overflow: hidden;
    flex: 1;
}

.ContextMenuItem {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-left-style: solid;
    border-left-width: 6px;
    border-left-color: transparent;
    text-decoration: none;
    color: var(--lawmonitor-gray-500);
    white-space: nowrap;
    cursor: pointer;
}
.Icon {
    width: 16px;
    height: 16px;
    box-sizing: content-box;
    padding-right: 10px;
}

.Active {
    background-color: var(--lawmonitor-blue-100);
    border-left-color: var(--lawmonitor-blue-200);
}

.ContextMenuFilter {
    background-color: #ffffff;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Closed */
.ContextMenu.Closed {
    min-width: 33px;
    max-width: 33px;
    width: 33px;
}

.Closed .Header {
    padding-left: 0;
    justify-content: center;
}

.Closed .Toggle {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    text-align: center;
    transform: rotate(180deg);
}

.Closed .ContextMenuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.Closed .Icon {
    padding: 0;
}

.Closed .Title,
.Closed .Text {
    display: none;
}

.Closed .Active .Icon {
    filter: invert(1) brightness(2);
}

.Closed .Active {
    background-color: var(--lawmonitor-blue-200);
}

.Closed .ContextMenuFilter {
    display: none;
}

.CheckboxAll {
    margin-left: auto;
    margin-right: 14px;
}
