.RevisionSettings {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--lawmonitor-gray-500);
    padding: 24px;
}

.Title {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
}

.Box {
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    padding: 16px 32px 24px 24px;
    max-width: 600px;
    font-size: 12px;
}

.BoxTitle {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 12px;
}
.BoxText {
    margin-top: 0;
    margin-bottom: 16px;
}

.InputContainer,
.ToggleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.InputContainer > p {
    margin: 0;
    margin-right: 26px;
}
.Input {
    max-width: 100px;
}
.Input > p:last-of-type {
    display: none;
}

.ToggleContainer > p {
    margin: 0;
    margin-right: 20px;
}
.Toggle {
    width: auto;
}

.ButtonContainer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}
.Button {
    font-size: 12px;
    padding: 4px 20px;
}
