.Modal {
    width: 100%;
}

.ErrorIcon {
    position: absolute;
    top: 20px;
    width: 24px;
    right: 32px;
}

.Modal > h4 {
    word-break: break-all;
}

.Content {
    width: 100%;
}

.Content p:last-of-type {
    margin-top: 16px;
    margin-bottom: 16px;
}

.Options {
    width: 100%;
    display: flex;
}

.Options button:first-of-type {
    margin-left: auto;
}