.MonitorButton {
    position: relative;
    font-size: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.MonitorButton .Header {
    width: 125px;
    height: 24px;
    color: var(--lawmonitor-gray-500);
    border-width: 1px;
    border-style: solid;
    border-color: var(--lawmonitor-gray-500);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 6px;
    padding-left: 8px;
    z-index: 500;
    position: relative;
    overflow: hidden;
}
.MonitorButton .Header.Monitored {
    color: #ffffff;
    background-color: var(--lawmonitor-blue-200);
    border-color: transparent;
}
.MonitorButton .Header.Monitored img {
    filter: invert(1) brightness(2);
}

.MonitorButton .Header img {
    transform: rotate(0);
}
.MonitorButton.Open .Header img {
    transform: rotate(90deg);
}

.MonitorButton.Open .List {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px;
    padding-top: 30px;
    width: 125px;
    background-color: #ffffff;
    border: 1px solid var(--lawmonitor-gray-500);
    border-radius: 8px;
    z-index: 400;
    white-space: nowrap;
}

.MonitorButton .List {
    display: none;
}

.MonitorButton .Header.Disabled {
    background-color: var(--lawmonitor-gray-400);
    color: #ffffff;
    border-color: transparent;
    cursor: help;
}
