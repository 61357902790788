.AccordionInputItem {
    height: 160px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 8px;
}
.AccordionInputItem > div > div:last-of-type {
    border: 1px solid var(--lawmonitor-gray-500);
}

.AccordionInputItem > div > div > div > div > div {
    padding: 6px 8px !important;
    font-size: 12px !important;
}
