/* Customize the label (the container) */
.Container {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-top: 16px;
    cursor: pointer;
    font-size: 12px;
    height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.Container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid var(--lawmonitor-gray-400);
    box-sizing: content-box;
}

/* On mouse-over, add a grey background color */
.Container:hover input ~ .Checkmark {
    background-color: var(--lawmonitor-gray-200);
}

/* When the radio button is checked, add a blue background */
.Container input:checked ~ .Checkmark {
    background-color: #ffffff;
    border: 2px solid var(--lawmonitor-blue-200);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.Container input:checked ~ .Checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.Container .Checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--lawmonitor-blue-200);
}

.Text {
    height: 100%;
    vertical-align: middle;
}
