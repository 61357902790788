.FullViewFooter {
    min-height: 96px;
    height: 96px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    color: #ffffff;
    padding: 10px 24px 16px 24px;
    font-size: 12px;
}

.Title,
.Text {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Buttons {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

/* NEW */
.FullViewFooter.NEW {
    background-color: var(--lawmonitor-green);
}
.FullViewFooter.NEW .Buttons button {
    color: var(--lawmonitor-green);
    border: none;
}

/* DELEGATED */
.FullViewFooter.DELEGATED {
    background-color: var(--lawmonitor-purple-200);
}
.FullViewFooter.DELEGATED .Buttons button {
    color: var(--lawmonitor-purple-200);
    border: none;
}

/* ME */
.FullViewFooter.ME {
    background-color: var(--lawmonitor-blue-200);
}
.FullViewFooter.ME .Buttons button {
    color: var(--lawmonitor-blue-200);
    border: none;
}
