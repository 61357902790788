.Modal {
    max-width: 580px;
}

.Content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ErrorIcon {
    position: absolute;
    top: 24px;
    right: 32px;
}

.CompanyList ul {
    padding-left: 24px;
    overflow-y: auto;
    padding-bottom: 20px;
    padding-top: 20px;
}

.Buttons {
    margin-top: 16px;
    margin-left: auto;
    justify-content: flex-end;
    float: right;
}
.Buttons button:last-of-type {
    margin-left: 16px;
}