.MergeLaws {
    height: 100%;
    overflow: hidden;
    display: flex;
}

.Content {
    width: 100%;
    overflow-y: auto;
}

.SourceWrapper {
    padding: 17px;
}

.DestinationWrapper {
    padding: 17px;
}

.SelectLaws {
    margin-top: 10px;
}

.SelectLaws {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
}

.SelectLaws > div:first-child {
    max-width: 100%;
    min-width: 184px;
    flex: 1;
    margin-bottom: 16px;
}

.Dropdown > div {
    width: 100%;
    max-height: 1000px;
}

.Header {
    z-index: 102;
}

.Dropdown > div > .ListWrapper > .List {
    /*fix me!*/
    max-height: 500px;
}

.Divider {
    height: 10px;
    border-bottom: 1px solid #5c5c5c;
    margin-bottom: 40px;
}

.MarginDiv {
    margin: 17px;
    align-items: start;
    justify-content: space-between;
}

.SelectedLaws {
    margin-top: 7px;
}

.End {
    display: flex;
    justify-content: end;
}

.CompanyButtonDiv {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.Button {
    position: relative;
}

.CompanyButton {
    margin-right: 40px;
}

.companyDiv {
    margin-left: 20px;
    line-height: 1.7;
    border-bottom: 1px solid #c8c8c8;
}

.Warning img {
    transition: 0.1s;
    width: 23px;
    margin-left: 17px;
    margin-top: 4px;
    opacity: 0.7;
}

.Warning img:hover {
    cursor: pointer;
    opacity: 1;
    width: 23px;
}

.Warning img:active {
    opacity: 0.7;
}