.Header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 5vh;
}

.Header img{
    filter: drop-shadow(1px 1px 1px lightgray);
}

.Title {
    font-size: 25px;
    font-family: var(--lawmonitor-proxima-soft);
}

.backButton{
    display: flex;
    margin-left: 25px;
    font-size: 16px;
    align-items: center;
}
.backButton:hover{
    margin-left: 20px;
    cursor: pointer;
}
.backImage{
    display: flex;
    width: 16px;
    margin-right: 1px;
    margin-top: 2px;
}
