.EditRevisionQuestionView {
    position: absolute;
    top: 0;
    left: 0;
    width: max(50%, calc(100% - 696px));
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow-y: auto;
    border-left: 1px solid var(--lawmonitor-gray-300);
    border-right: 1px solid var(--lawmonitor-gray-300);
    z-index: 1100;
    color: var(--lawmonitor-gray-500);
}

.Header {
    height: 24px;
    min-width: 100%;
    padding: 8px;
    background-color: var(--lawmonitor-gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header span {
    font-size: 10px;
}
.Header p {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 44px;
    margin-right: auto;
}

.Content > h4 {
    padding: 17px;
    margin: 0;
    font-size: 14px;
    color: var(--lawmonitor-gray-500);
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}

.Content {
    height: 100%;
    overflow-y: auto;
}

.Buttons {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 26px 24px;
}
.Buttons button:last-of-type {
    margin-left: 16px;
}
.Buttons button {
    font-size: 12px;
    padding: 4px 32px 5px 32px;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .EditRevisionQuestionView {
        width: 50%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .EditRevisionQuestionView {
        width: 50%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .EditRevisionQuestionView {
        width: 50%;
    }
}
