.Keywords {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--lawmonitor-gray-500);
}

.Top {
    padding: 24px;
}

.Title {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
}

.Information {
    position: relative;
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    padding: 18px 24px 18px 24px;
    width: 100%;
    font-size: 12px;
}
.Information h6 {
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    height: 24px;
    display: inline-flex;
    align-items: center;
}
.Information .Icon {
    margin-top: 1px;
    margin-left: 8px;
    transform: rotate(0);
}
.Information .Icon.Open {
    transform: rotate(90deg);
}
.Information p:last-of-type {
    margin-bottom: calc(24px + 12px);
}
.Information .Buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 24px;
    bottom: 18px;
}
.Information .Buttons button {
    padding: 4px 20px;
    font-size: 12px;
}

.SearchBar {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: var(--lawmonitor-gray-100);
}

.SearchContainer {
    display: flex;
    align-items: center;
}
.SearchCounter {
    margin-left: 12px;
    font-size: 10px;
    color: var(--lawmonitor-gray-500);
    white-space: nowrap;
}
.SearchCounter.Filtered {
    color: var(--lawmonitor-red);
}

.Toggle {
    width: auto;
}

.TableWrapper {
    flex: 1;
}

.TableHeader {
    display: flex;
    align-items: center;
    background-color: var(--lawmonitor-gray-100);
    border-top: 1px solid var(--lawmonitor-gray-200);
}

/* Target Table Cell */
.Keywords div[role='cell'] {
    padding: 12px 20px !important;
}
.Keywords div[role='cell'] > div {
    height: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Keywords div[role='rowgroup'] {
    overflow: visible !important;
}
