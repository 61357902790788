.FullViewHeader {
    height: 40px;
    width: 100%;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.FullViewHeader.New {
    background-color: var(--lawmonitor-green);
}
.FullViewHeader.Change {
    background-color: var(--lawmonitor-red);
}

.Text {
    display: flex;
    flex-direction: column;
}
.Text h6,
.Text p {
    font-size: 10px;
    margin: 0;
    color: #ffffff;
}

.FullViewHeader button,
.FullViewHeader button:hover {
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
}

.New button,
.New button:hover {
    color: var(--lawmonitor-green);
}
.Change button,
.Change button:hover {
    color: var(--lawmonitor-red);
}
