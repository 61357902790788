.AcknowledgeChangeView {
    position: absolute;
    top: 0;
    left: 0;
    width: max(50%, calc(100% - 696px));
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow-y: auto;
    border-left: 1px solid var(--lawmonitor-gray-300);
    border-right: 1px solid var(--lawmonitor-gray-300);
    z-index: 1100;
    color: var(--lawmonitor-gray-500);
}

.CloseBar {
    height: 24px;
    min-width: 100%;
    padding: 8px;
    background-color: var(--lawmonitor-gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
}

.CloseBar span {
    font-size: 10px;
}

.Content {
    padding: 16px 32px 24px 32px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}
.Content .Title {
    font-size: 14px;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 0;
}

.Content.Comment {
    height: 200px;
}
.Content.Comment h5 {
    font-size: 14px;
    font-weight: bold;
}

.Content .Buttons {
    margin-top: 16px;
}
.Content .Buttons button:first-child {
    margin-right: 16px;
}

.RevisionQuestionButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.RevisionQuestionButton img {
    margin-left: 6px;
    height: 14px;
}

.Disabled {
    opacity: 0.4;
    pointer-events: none;
}

.SelectUsers {
    margin-top: 13px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
}
.SelectUsers > div:first-child {
    margin-right: 32px;
    max-width: 290px;
    min-width: 184px;
    flex: 1;
    margin-bottom: 16px;
}
.SelectUsers > div:first-child > div:first-child {
    width: 100%;
}
.SelectUsers > div:first-child > div:nth-child(2) {
    width: 100%;
}

.SelectUsers > div:nth-child(2) {
    width: unset;
    height: 24px;
}

.Footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px 32px;
}
.Footer button {
    font-size: 12px;
    padding: 4px 32px 5px 32px;
}
.Footer button:first-child {
    margin-right: 16px;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .AcknowledgeChangeView {
        width: 50%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .AcknowledgeChangeView {
        width: 50%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .AcknowledgeChangeView {
        width: 50%;
    }
}
