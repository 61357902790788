.DocumentPage {
    display: flex;
    flex-direction: column;
    color: var(--lawmonitor-gray-500);
    margin-bottom: 20px;
    overflow-y: auto;
    width: inherit;
}

.DocumentPageHeader {
    font-size: 18px;
}

.Info {
    padding-left: 24px;
    padding-right: 24px;
    height: 105px;
    margin-bottom: 16px;
    background-color: var(--lawmonitor-gray-100);
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-300);
}

.Documents {
    background-color: white;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    margin-left: 23px;
    margin-right: 33px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.DocumentType {
    font-weight: bold;
    margin-bottom: 0;
}

.FileNameLink {
    cursor: pointer;
    color: var(--lawmonitor-blue-200);
    word-wrap: break-word;
    max-width: 320px;
}
.FileNameLink .LinkIcon {
    height: 14px;
    width: 14px;
    margin-left: 4px;
}

.Cancel {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}