.Container {
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    max-width: 250px;
    width: 100%;
}

.Label {
    margin-top: 0;
    margin-bottom: 6px;
}

.InputContainer {
    position: relative;
    width: 100%;
}

.Input {
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-500);
}

.Input.Error {
    padding-right: 32px;
}

.ErrorIcon {
    position: absolute;
    right: 8px;
    top: 4px;
}

.Container > .SubLabel {
    height: 12px;
    font-size: 10px;
    margin-top: 6px;
    margin-bottom: 0;
    color: var(--lawmonitor-gray-400);
    text-align: end;
}
.SubLabel.Error {
    color: var(--lawmonitor-red);
}

.InputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.InputFile + label {
    font-size: 14px;
    padding: 5px 34px;
    margin-bottom: 0;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    color: #ffffff;
    background-color: rgb(25, 157, 226);
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border-radius: 6px;
    border: 1px solid transparent;
}

.InputFile:focus + label,
.InputFile:active,
.InputFile + label:hover {
    text-decoration: none;
    background-color: rgba(25, 157, 226, 0.7);
    border-color: transparent;
    color: #ffffff;
    border-color: transparent;
}

.InputFile + label {
    cursor: pointer;
}
