.RevisionCell {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.Content {
    white-space: normal;
    font-size: 12px;
    line-height: 19px;
    min-width: 0;
    width: 100%;
    overflow: hidden;
}

.Name {
    color: var(--lawmonitor-gray-500);
    font-weight: bold;
}

@supports (-webkit-line-clamp: 1) {
    .MultiRow {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}

@supports not (-webkit-line-clamp: 1) {
    .MultiRow {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}