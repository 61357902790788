.ExportRevisionModal {
    overflow: initial;
}

.ExportRevisionModal > h4 {
    margin-bottom: 8px;
}

.ExportRevisionModal > div {
    display: flex;
    flex-direction: column;
}

.RadioButtonContainer > p {
    margin-top: 0;
    margin-left: 24px;
    color: var(--lawmonitor-gray-400);
    font-style: italic;
}

.Options {
    width: 100%;
}

.DatePicker {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;
}
.DatePicker > p {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 16px;
}
.DatePicker button {
    padding: 0;
}

.Buttons {
    margin-top: 44px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.Buttons button:last-of-type {
    margin-left: 16px;
}
