.EditRevision {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Content {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.List {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.TopbarChildren {
    display: flex;
    align-items: center;
}

.TopbarChildren > div {
    width: 170px;
}

.TopbarChildren button {
    font-size: 12px;
    padding: 4px 20px;
    margin-left: 24px;
}

.TopbarStatus {
    color: var(--lawmonitor-gray-500);
    font-size: 12px;
    display: flex;
    align-items: center;
}
.TopbarStatus img {
    margin-left: 16px;
}

.TableWrapper {
    position: relative;
    height: 100%;
    width: 100%;
}
