.Table {
    border-spacing: 0;
}

.TH {
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    width: 33%;
}

.TableData {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid black;
}

.TableContainer{
    height: 70vh;
    overflow: scroll;
    padding-bottom: 50px;
}

.StatusCell {
    width: 2%;
}

.TableHead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.Aligner {
    display: flex;
    justify-content:center;
}

.LawsCellHeader{
    display: flex;
    justify-content:flex-start;
    margin-left: 1vh;
}

.SortIcon {
    margin-left: 3px;
}

.SortDesc,
.SortAsc,
.SortNone {
    margin-left: 5px;
    display: flex;
    align-items: center;
}
.SortAsc path,
.SortDesc path {
    fill: var(--lawmonitor-gray-400);
}
.SortDesc svg {
    transform: rotate(270deg);
}
.SortAsc svg {
    transform: rotate(90deg);
}
.SortNone svg {
    width: 6px;
}

.Cell {
    height: 10vh;
}

.Cell:nth-child(2n) {
    background-color: #EEEEEE;
}


@keyframes example {
    from{background-color: lightgray;}
    to {background-color: lightgray;}
}

.Cell:hover{
    animation-name: example;
    animation-duration: 1ms;
}

