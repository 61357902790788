.Modal {
    left: 80%;
    top: 30%;
}

.Modal > h4{
    margin-bottom: 20px;
}

.Content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.WarningIcon {
    position: absolute;
    top: 22px;
    right: 32px;
}

.Options {
    margin-top: 24px;
    margin-left: auto;
}
.Options button:last-of-type {
    margin-left: 16px;
}

@media screen and (max-width: 1200px) {
    .Modal {
        left: 50%;
    }
}
