.Laws {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
}

.Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.List {
    position: relative;
    height: 100%;
    overflow: hidden;
}
