.Home {
    height: 100%;
    overflow-y: auto;
}

.MiniBanner {
    background-color: var(--lawmonitor-gray-100);
    height: 56px;
    font-family: var(--lawmonitor-proxima-soft);
}
.MiniBanner .Content {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 950px;
    margin-right: auto;
    padding-left: 99px;
}
.MiniBanner .Text {
    margin-right: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.MiniBanner .Text h3 {
    color: var(--lawmonitor-gray-500);
    margin-top: 0;
    margin-bottom: 4px;
}

.Banner {
    background-color: var(--lawmonitor-gray-100);
    padding-top: 60px;
    padding-bottom: 60px;
    height: 300px;
    font-family: var(--lawmonitor-proxima-soft);
}
.Banner .Content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 56px;
    padding-right: 56px;
}
.Banner .Text {
    margin-right: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Banner .Text h1 {
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 8px;
}
.Banner .Text p {
    font-size: 16px;
    margin-top: 0;
}

.Banner .Video {
    position: relative;
    margin-left: 22px;
    width: 100%;
}

.Banner .Video video {
    width: auto;
}

.NewsFeed,
.DashboardStatus {
    display: flex;
    flex-direction: column;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-family: var(--lawmonitor-proxima-soft);
}

.NewsFeed h3 {
    display: flex;
    align-items: center;
}
.NewsFeed > h3 > img {
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-left: 10px;
}
.NewsFeed > h3 > img:hover {
    filter: brightness(0);
}
