.Content {
    height: 100%;
    padding: 8px 24px 16px 24px;
    overflow-y: auto;
}

.ForceAcceptDelegateButton {
    color: #ffffff !important;
    background-color: var(--lawmonitor-purple-100);
    margin-right: 16px;
}
.ForceAcceptDelegateButton:hover {
    color: var(--lawmonitor-purple-200) !important;
}
