.ReadersLogins {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--lawmonitor-gray-500);
    overflow-y: auto;
}

.Container {
    padding: 24px;
    max-height: 0;
}

.Title {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
}

.Information {
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    padding: 18px 24px 24px 24px;
    max-width: 600px;
    font-size: 12px;
    margin-bottom: 24px;
}
.Information h6 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 12px;
}

.ToggleContainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
}
.ToggleContainer > p {
    margin: 0;
    margin-right: 20px;
}
.Toggle {
    width: auto;
}

.Information .Buttons {
    display: flex;
    justify-content: flex-end;
}
.Information .Buttons button {
    padding: 4px 20px;
    font-size: 12px;
}

.LoginSection {
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-200);
    background: var(--lawmonitor-gray-100);
    max-width: 600px;
    font-size: 12px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.LoginSectionWrapper:last-child {
    padding-bottom: 25px;
}

.LoginSection.Disabled {
    opacity: 0.3;
    pointer-events: none;
}
.LoginSection h3 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 12px;
}
.TopSection > div:first-of-type {
    max-width: 100%;
}

.TopSection {
    padding: 16px 32px 24px 24px;
    display: flex;
    flex-direction: column;
}

.FlexWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.FlexWrapper > div {
    width: 240px;
    margin-bottom: 16px;
}
.DropdownTitle {
    margin-top: 0;
    margin-bottom: 8px;
}
.FlexWrapper > div > div > div:first-of-type {
    width: 100%;
}
.FlexWrapper > div:nth-of-type(1) {
    z-index: 5;
}
.FlexWrapper > div:nth-of-type(2) {
    z-index: 4;
}
.FlexWrapper > div:nth-of-type(3) {
    z-index: 3;
}
.FlexWrapper > div:nth-of-type(4) {
    z-index: 2;
}
.LanguageDropdown {
    z-index: 1;
}
.InputRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    z-index: 1;
}

.InputRow > div {
    width: 184px;
}

/* Language dropdown */
.InputRow > div:last-of-type,
.InputRow > div:last-of-type > div {
    max-width: 136px;
    width: 136px;
    min-width: 136px;
}

.TopSection > .ToggleContainer {
    margin-top: 8px;
}
.TopSection > .ToggleContainer > p {
    min-width: 140px;
    white-space: nowrap;
}

.LoginLink {
    margin: 0;
    padding: 16px 32px 16px 24px;
    border-top: 1px solid var(--lawmonitor-gray-200);
    border-bottom: 1px solid var(--lawmonitor-gray-200);
    display: flex;
    align-items: center;
    font-size: 10px;
}
.LoginLink > img {
    margin-right: 8px;
    cursor: pointer;
}
.LoginLink > img:focus {
    border-radius: 7px;
    background-color: var(--lawmonitor-blue-200);
}

.SectionFooter {
    display: flex;
    justify-content: flex-end;
    padding: 24px 32px 24px 24px;
}
.SectionFooter > button {
    padding: 4px 20px;
    font-size: 12px;
}
.SectionFooter > button:last-of-type {
    margin-left: 16px;
}

.LawListDropdown > div {
    width: 100%;
}
