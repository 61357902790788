.RevisionDateCell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--lawmonitor-gray-500);
    height: 100%;
    width: 100%;
}

.RevisionDateCell.RevisionWarning {
    color: var(--lawmonitor-red);
}
