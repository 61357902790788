.Content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Warning {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
}

.Warning img {
    width: 30px;
}

.SelectedLaws {
    margin-bottom: 22px;
    margin-top: 11px;
}

.ButtonRow1 {
    margin-top: 16px;
    margin-left: auto;
}

.ButtonRow1 button:last-of-type {
    margin-left: 16px;
}