.MenuCell {
    position: relative;
    width: 24px;
    height: 24px !important;
    cursor: pointer;
}

.Menu,
.SubMenu {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 176px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 888;
    padding-top: 12px;
    padding-bottom: 12px;
}

.Menu .Title {
    font-size: 10px;
    color: var(--lawmonitor-gray-400);
    font-weight: bold;
    margin: 0;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
}
.Menu .Item,
.SubItem {
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0;
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    padding-left: 12px;
    padding-right: 12px;
}

.Item:hover,
.SubItem:hover {
    background-color: var(--lawmonitor-gray-100);
}
.Menu .Item.Disabled {
    opacity: 0.4;
    pointer-events: none;
}

.MenuIcon {
    position: absolute;
    height: 100%;
    width: 100%;
}
.MenuIcon.Open {
    z-index: 999;
}

.SubMenu {
    top: 0;
    right: 100%;
    padding: 0;
}
.Menu > div {
    position: relative;
}
.SubMenuIconSpacer {
    width: 10px;
    display: inline-block;
}

.Menu img {
    padding-right: 4px;
    padding-left: 0;
}
.Menu img.SubMenuOpen {
    transform: rotate(180deg);
    padding-right: 0;
    padding-left: 4px;
}
