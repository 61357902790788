.Modal {
    width: 100%;
    max-width: 450px;
    padding: 24px 32px 32px 32px;
}
.Modal h4 {
    margin-bottom: 24px;
}

.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Input {
    max-width: 100%;
}

.DropdownInput {
    width: 100%;
    margin-bottom: 18px;
}
.FirstDropdown {
    z-index: 1050;
}
.DropdownInput.Disabled {
    opacity: 0.3;
    pointer-events: none;
}
.DropdownInput > p {
    margin-top: 0;
    margin-bottom: 6px;
}
.DropdownInput > div,
.DropdownInput > div > div {
    width: 100%;
    max-height: 150px;
}

.TextInput {
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 24px;
}
.TextInput > p {
    margin-top: 0;
    margin-bottom: 6px;
}
.TextInput > div > div:first-of-type {
    height: 63px;
    border: 1px solid var(--lawmonitor-gray-500);
}

.TextInput > div > div:first-of-type > div > div > div {
    padding: 6px 8px !important;
    font-size: 12px !important;
}

.Buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.Buttons > button:last-of-type {
    margin-left: 16px;
}
