.Name {
    font-size: 14px;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

p {
    font-size: 12px;
    margin: 0;
}

.Card {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow: hidden;
    white-space:nowrap;
    border-radius: 5px;

}

.Comment {
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 24px;
    font-size: 14px;
    font-style: italic;
}

.Card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}

.ImageContainer {
    display: flex;
    justify-content: center;
}

.TextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.Image{
    object-fit: cover;
    width: 16vh;
    height: 16vh;
    border-radius: 2px;

}

@media screen and (max-width: 600px) {
    p {
        font-size: 12px;
    }

    h5 {
        font-size: 12px;
    }

    .Card {
        width: 19vh;
        padding: 10px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    }

    .Card:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
        cursor: pointer;
    }
}
