.DateRangePicker {
    display: flex;
    align-items: center;
}

.DatePicker {
    height: 24px;
    width: 93px;
    border: 1px solid var(--lawmonitor-gray-500);
    color: var(--lawmonitor-gray-500);
    border-radius: 6px;
    padding-left: 10px;
    font-size: 12px;
    cursor: pointer;
}

.Separator {
    width: 22px;
    color: var(--lawmonitor-gray-500);
    text-align: center;
}
