.Container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
    max-width: 250px;
    width: 100%;
}

.Label {
    margin-top: 0;
    margin-bottom: 6px;
}

.InputContainer {
    position: relative;
    width: 100%;
}

.Input {
    height: 24px;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-500);
}

.Input.Error {
    padding-right: 32px;
}

.ErrorIcon {
    position: absolute;
    right: 8px;
    top: 4px;
}

.Container > .SubLabel {
    height: 12px;
    font-size: 10px;
    margin-top: 6px;
    margin-bottom: 0;
    color: var(--lawmonitor-gray-400);
    text-align: end;
}
.SubLabel.Error {
    color: var(--lawmonitor-red);
}

@media screen and (max-width: 800px) {
    .Input {
        font-size: 16px;
    }
}