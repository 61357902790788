.Modal {
  width: 100%;
}
.Modal > h4 {
  margin-bottom: 16px;
}
.Modal > div {
  display: flex;
  flex-direction: column;
}

.TextEditor {
  height: 124px;
  width: 100%;
  border-radius: 6px;
  margin-top: auto;
  margin-bottom: 8px;
  border: 1px solid var(--lawmonitor-gray-400);
  resize: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.Buttons button:last-of-type {
  margin-left: 16px;
}