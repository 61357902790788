.FullView {
    position: absolute;
    top: 0;
    right: min(-50%, 696px);
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--lawmonitor-gray-300);
    z-index: 1000;
    max-width: 696px;
}

.FullView.Open {
    right: 0;
}

.CloseBar {
    height: 24px;
    width: 100%;
    padding: 8px;
    background-color: var(--lawmonitor-gray-300);
    display: flex;
    align-items: center;
}
.CloseBar img {
    cursor: pointer;
}

.HiddenIdBar {
    position: absolute;
    margin-left: 77%;
    font-size: 10px;
    opacity: 0;
}

.HiddenIdBar:hover {
    transition: opacity ease-in 6s;
    cursor: default;
    opacity: 1;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 24px;
    font-size: 14px;
    color: var(--lawmonitor-gray-500);
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}
.Header .Title {
    margin-right: 40px;
    font-weight: bold;
}
.Header .LawName {
    word-break: break-word;
}
.Header .LawName.Link {
    cursor: pointer;
    color: var(--lawmonitor-blue-200);
}
.Header .LawName.Link .LinkIcon {
    height: 14px;
    width: 14px;
    margin-left: 4px;
}
.Header .SubId {
    font-weight: normal;
}
.SubId .Expired {
    color: red;
}
.SubId {
    display: flex;
}

.StatusIcon {
    margin-left: 10px;
    display: flex;
    align-self: center;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .FullView {
        right: -50%;
        max-width: none;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .FullView {
        right: -50%;
        max-width: none;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .FullView {
        right: -50%;
        max-width: none;
    }
}

 /*Firefox (all versions) CSS */
@supports  (-moz-appearance:none) {
    .FullView {
        right: -700px;
    }
}

@media screen and  (max-width: 800px) {
    .FullView {
        width: 100%;
        right: min(-100%);
        padding-top: 32px;
        transition: all 0.5s ease;
    }
}