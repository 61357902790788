.Button {
    font-size: 14px;
    padding: 6px 12px;
    margin-bottom: 0;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 6px;
}
.Button:focus,
.Button:active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.Button:hover,
.Button:focus {
    color: #333;
    text-decoration: none;
}
.Button:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

/* Big */
.Big {
    padding: 12px 40px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
}

/* Primary */
.Primary {
    color: #ffffff;
    background-color: rgb(25, 157, 226);
    border-color: transparent;
}
.Primary:hover,
.Primary:focus,
.Primary:active {
    color: #ffffff;
    background-color: rgba(25, 157, 226, 0.7);
    border-color: transparent;
}
.Primary:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Secondary */
.Secondary {
    color: #ffffff;
    background-color: rgb(131, 208, 247);
}
.Secondary:hover,
.Secondary:focus,
.Secondary:active {
    color: #ffffff;
    background-color: rgba(131, 208, 247, 0.7);
    border-color: transparent;
}

/* Light */
.Light {
    color: #009de0;
    background-color: #ffffff;
}
.Light:hover,
.Light:focus,
.Light:active {
    color: #009de0;
    background-color: #e2e6ea;
    border-color: transparent;
}
.Light:disabled {
    background-color: #e2e6ea;
    cursor: not-allowed;
}

/* Outline */
.Outline {
    color: var(--lawmonitor-gray-500);
    background-color: #ffffff;
    border: 1px solid var(--lawmonitor-gray-500);
    font-size: 12px;
    padding: 4px 32px 5px 32px;
}
.Outline:hover,
.Outline:active {
    background-color: #e2e6ea;
}
.Outline:disabled {
    background-color: #e2e6ea;
    cursor: not-allowed;
}
