.Modal {
    width: 375px;
    height: 150px;
    left: 80%;
    top: 30%;
}

.Modal > h4 {
    word-break: break-all;
}

.Content {
    width: 100%;
}

.InputField {
    max-width: 311px;
    margin-top: 16px;
    width:100%;
}

.Options {
    width: 100%;
    display: flex;
}

.Options button:first-of-type {
    margin-left: auto;
}

.Options button:last-of-type {
    margin-left: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 1200px) {
    .Modal {
        left: 50%;
    }
}