.AcknowledgeEditView {
    position: absolute;
    top: 0;
    left: 0;
    width: max(50%, calc(100% - 696px));
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow-y: auto;
    border-left: 1px solid var(--lawmonitor-gray-300);
    border-right: 1px solid var(--lawmonitor-gray-300);
    z-index: 1300;
    color: var(--lawmonitor-gray-500);
}

.HeaderClassName {
    width: 200px;
    background-color: #00a300;
}

.Header {
    height: 24px;
    width: 100%;
    padding: 8px;
    background-color: var(--lawmonitor-gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header span {
    font-size: 10px;
}

.Content {
    padding: 16px 32px 24px 32px;
    display: flex;
    flex-direction: column;
}
.Content .Title {
    font-size: 14px;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 16px;
}
.TextEditor {
    height: 100%;
    max-height: 500px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}

.Dropdowns {
    display: flex;
}
.Dropdowns > div:first-of-type {
    margin-right: 16px;
}

.Footer {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    padding: 24px;
}
.Footer button {
    font-size: 12px;
    padding: 4px 32px 5px 32px;
}
.Footer button:first-child {
    margin-right: 16px;
}

.ModalButtons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
}
.ModalButtons div button:last-of-type {
    margin-left: 16px;
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .AcknowledgeEditView {
        width: 50%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .AcknowledgeEditView {
        width: 50%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .AcknowledgeEditView {
        width: 50%;
    }
}
