.HardLockBackground {
    background-color: var(--lawmonitor-gray-100);
    height: 100%;
}

.HardLock {
    width: 488px;
    height: 270px;
    margin-top: 220px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    background-color: white;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 6px;
}

.Message {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.Message p {
    font-size: 16px;
    text-align: center;
    color: var(--lawmonitor-gray-500);;
}

.Header {
    padding-top: 42px;
    margin-bottom: 20px;
}

.Header p {
    font-family: local('proxima-soft');
    color: var(--lawmonitor-blue-300);
    text-align: center;
    margin: 0;
    font-size: 29px;
    line-height: 100%;
    font-weight: 600;
}

.Icon {
    opacity: 0.7;
}

.Button {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}