.AccordionCommentItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.Header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.Header p {
    margin-top: 0;
    margin-bottom: 6px;
}
