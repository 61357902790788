.EditDetachedCopiesToaster {
    position: absolute;
    right: 50%;
    bottom: 0;
    min-height: 56px;
    width: 616px;
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: var(--lawmonitor-blue-200);
    transform: translateX(50%);
}

.EditDetachedCopiesToaster.FullViewOpen {
    right: calc(50% + calc(min(50%, 696px) / 2));
}

.Text h6,
.Text p {
    font-size: 12px;
    margin: 0;
}

.Buttons button:first-of-type {
    margin-right: 12px;
    background-color: var(--lawmonitor-blue-300);
}

.Buttons button {
    padding: 4px 22px;
    font-size: 12px;
}

@media screen and (max-width: 1500px) {
    .EditDetachedCopiesToaster.FullViewOpen {
        flex-direction: column;
        align-items: flex-start;
        min-height: 85px;
        width: 385px;
    }

    .EditDetachedCopiesToaster.FullViewOpen .Buttons {
        margin-top: 10px;
    }
}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .EditDetachedCopiesToaster.FullViewOpen {
        right: 75%;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .EditDetachedCopiesToaster.FullViewOpen {
        right: 75%;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .EditDetachedCopiesToaster.FullViewOpen {
        right: 75%;
    }
}
