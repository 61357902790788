.SignContractContainer {
    overflow: scroll;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    float: none;
}

.ContractPlacement {
}

.SignatureHeader {
    font-size: 18px;
    margin-left: 24px;
}

.SignatureContainer {
    width: 800px;
    min-height: 175px;
    margin-left: 24px;
    margin-bottom: 100px;
    padding: 16px 24px;
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-300);
    border-radius: 6px;
}

.SignatureContainer p {
    font-size: 12px;
}

.Input {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13px;
    max-width: 300px;
}

.ButtonGroup {
    display: flex;
}

.SignButton, .DeclineButton {
    width: 208px;
    border-radius: 30px;
    font-size: 19px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
}

.SignButton, .SignButton:hover {
    background-color: var(--lawmonitor-green);
    color: white;
}

.DeclineButton, .DeclineButton:hover {
    background-color: var(--lawmonitor-red);
    color: white
}

.DeclineButton:hover {
    opacity: 0.7;
}

.SignButton:hover {
    opacity: 0.7;
}

.SignButton:hover:disabled {
    opacity: 0.4;
}

.NominateCertifier {
    border-top: 1px solid var(--lawmonitor-gray-400);
    margin-top: 18px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Dropdown {
}

