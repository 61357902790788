.Contact {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
}

.Information {
    padding: 16px 24px;
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    max-width: 600px;
    font-size: 12px;
}
.Information h6 {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 16px;
}
.Information p {
    margin-top: 0;
    margin-bottom: 16px;
}
.Information a {
    color: var(--lawmonitor-blue-200);
}

.Form {
    margin-top: 16px;
    background-color: var(--lawmonitor-gray-100);
    border: 1px solid var(--lawmonitor-gray-200);
    border-radius: 6px;
    max-width: 600px;
    font-size: 12px;
}

.FormHeader {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid var(--lawmonitor-gray-200);
}
.FormHeader h6 {
    font-size: 12px;
}
.FormContent {
    display: flex;
    flex-direction: column;
    padding: 16px 28px 20px 24px;
}

.DropdownInput {
    max-width: 250px;
    width: 100%;
    margin-bottom: 18px;
}
.DropdownInput > p {
    margin-top: 0;
    margin-bottom: 6px;
}
.DropdownInput > div,
.DropdownInput > div > div {
    width: 100%;
}

.Textarea > p {
    margin-top: 0;
    margin-bottom: 6px;
}
.Textarea textarea {
    width: 100%;
    height: 160px;
    min-height: 40px;
    max-height: 260px;
    border-radius: 6px;
    margin-top: auto;
    border: 1px solid #3f3f3f;
    resize: vertical;
    margin-bottom: 16px;
}

.Button button {
    padding: 4px 24px 5px 24px;
}

.ConfirmModalButton {
    margin-left: auto;
    margin-top: 12px;
}
