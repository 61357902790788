.ChangeTextLink {
    color: var(--lawmonitor-blue-200);
    cursor: pointer;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}
.ChangeTextLink:hover {
    text-decoration: underline;
}

.LinkIcon {
    height: 12px;
    margin-left: 4px;
}
