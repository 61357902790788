.FullView {
    position: absolute;
    top: 0;
    right: min(-50%, 696px);
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--lawmonitor-gray-300);
    z-index: 1000;
    max-width: 696px;
    font-size: 12px;
}

.FullView.Open {
    right: 0;
}

.CloseBar {
    height: 24px;
    width: 100%;
    padding: 8px;
    background-color: var(--lawmonitor-gray-300);
    display: flex;
    align-items: center;
}

.CloseBar img {
    cursor: pointer;
}

.CloseBar p {
    width: 100%;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    font-family: Verdana;
    font-size: 10px;
    line-height: 12px;
    color: #484848;
    font-style: normal;
    font-weight: normal;

}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 24px;
    font-size: 14px;
    color: var(--lawmonitor-gray-500);
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}

.Header .Title {
    margin-right: 40px;
    font-weight: bold;
}

.Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
}

.Information {
    padding: 17px 24px 20px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
}

.Information td:first-child {
    width: 168px;
    padding: 10px 0px 10px 0px;
}

.Information td:last-child {
    padding-left: 1px;
}

.Information .Pen {
    cursor: pointer;
    margin-left: 8px;
    margin-bottom: -4px;
    height: 16px;
    position: relative;
}

.UpdatePriceButton {
    margin-left: 16px;
    font-size: 12px;
    padding: 4px 12px;
    max-width: 100%;
}

.ResetAllPricesForGroupButton {
    background-color: var(--lawmonitor-gray-400);
    font-size: 12px;
    padding: 4px 12px;
    max-width: 100%;
}

.ResetAllPricesForGroupButton:hover {
    background-color: var(--lawmonitor-gray-400);
    opacity: 0.7;
}


.AccordionContent {
    padding: 12px 24px 50px 24px;

}

/* IE10+ CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .FullView {
        right: -50%;
        max-width: none;
    }
}

/* Edge 12+ CSS */
@supports (-ms-accelerator: true) {
    .FullView {
        right: -50%;
        max-width: none;
    }
}

/* Edge 16+ CSS */
@supports (-ms-ime-align: auto) {
    .FullView {
        right: -50%;
        max-width: none;
    }
}

.Dropdown,
.Dropdown > div {
    width: 208px;
}

/*Firefox (all versions) CSS */
@supports (-moz-appearance:none) {
    .FullView {
        right: -700px;
    }
}