.Container {
    position: relative;
    margin-left: 24px;
    height: 32px;
}

.Container img {
    cursor: pointer;
}

.Content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 440px;
    background-color: #ffffff;
    z-index: 9999;
    left: -130px;
    top: 37px;
    filter: drop-shadow(0 3px 6px #00000029);
    border-radius: 6px;
    padding: 16px 16px 24px 16px;
}

.Content::after {
    content: '';
    position: absolute;
    border: 16px solid transparent;
    border-bottom: 16px solid #ffffff;
    top: -32px;
    left: 132px;
    pointer-events: none;
}

.Title {
    color: var(--lawmonitor-gray-500);
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 16px;
}

.Comments {
    background-color: var(--lawmonitor-gray-300);
    white-space: pre;
    font-size: 12px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 16px;
    height: 100%;
}

.Input {
    width: 100%;
    height: 100%;
    max-height: 75px;
    border-radius: 6px;
    margin-top: auto;
    margin-bottom: 8px;
    border: 1px solid #3f3f3f;
    resize: none;
}

.Button {
    margin-left: auto;
    padding: 4px 20px;
    font-size: 12px;
}

.Disabled .Input,
.Disabled .Button {
    display: none;
}
.Disabled .Comments {
    margin-bottom: 0;
}
