.Agreement > div {
    max-height: 0;
}

.Agreement {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--lawmonitor-gray-500);
    padding-left: 48px;
    margin-bottom: 20px;
    overflow-y: auto;
}

.Info {
    padding-left: 24px;
    padding-right: 24px;
    width: 595px;
    height: 170px;
    margin-bottom: 16px;
    background-color: var(--lawmonitor-gray-100);
    font-size: 12px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-300);
}

.Info a {
    color: var(--lawmonitor-blue-200);
    font-weight: bold;
}

.AgreementTableWrapper > div {
    max-height: 0;
}

.AgreementTableWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    /*overflow-y: auto;*/
}

.AgreementTable {
    width: 595px;
    background-color: var(--lawmonitor-gray-100);
    font-size: 10px;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-300);
    margin-bottom: 16px;
}

.AgreementTable h4 {
    padding-left: 24px;
    font-size: 12px;
}

.AgreementTable table {
    border-collapse: collapse;
}

.AgreementTable table > tr td {
    padding: 13px 16px;
    border: 1px solid var(--lawmonitor-gray-300);
}
