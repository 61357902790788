.TextEditor {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.Header p {
    margin: 0;
    font-size: 14px;
}

.Editor {
    flex-grow: 1;
    border-radius: 6px;
    border: 1px solid var(--lawmonitor-gray-300);
    overflow: hidden;
    height: 300px;
}
.Editor :global(.DraftEditor-root),
.Editor :global(.DraftEditor-editorContainer) {
    height: 100%;
    overflow: hidden;
}
.Editor :global(.public-DraftEditor-content) {
    height: 100%;
    padding: 20px;
    font-size: 14px;
    overflow: auto;
}

.Editor :global(.public-DraftEditor-content) > div > div {
    margin-bottom: 1em;
}

.ErrorBar {
    display: inline-flex;
    margin-left: auto;
}

.Validation {
    padding-top: 5px;
    padding-right: 5px;
    font-size: 12px;
    color: var(--lawmonitor-gray-500);
}
.Validation.NotValid {
    color: red;
}

.Validation.Counter {
    margin-left: 10px;
}

.Container > .SubLabel {
    height: 12px;
    font-size: 10px;
    margin-top: 6px;
    margin-bottom: 0;
    color: var(--lawmonitor-gray-400);
    text-align: end;
}

@media screen and (max-width: 800px){
    .Header {
        display: block;
        margin-bottom: 0;
    }

    .Options {
        display: flex;
        justify-content: end;
        margin-top: 10px
    }

    .Editor :global(.public-DraftEditor-content) {
        font-size: 16px;
    }

    .Editor {
        height: 150px;
    }
}
