.Modal {
    width: 100%;
}

.Content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Comments {
    display: flex;
    flex-direction: column;
}

.CommentTitle {
    margin-bottom: 8px;
}

.Comment {
    background-color: var(--lawmonitor-gray-100);
    padding: 8px 14px;
    height: 130px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    overflow-y: auto;
    margin-top: 0;
    margin-bottom: 8px;
}

.Input {
    height: 85px;
    border: 1px solid #3f3f3f;
    border-radius: 6px;
    vertical-align: text-top;
    padding: 6px 14px;
    resize: none;
}

.CommentButton {
    width: fit-content;
    margin-left: auto;
    margin-top: 8px;
    padding: 4px 20px !important;
    margin-bottom: 48px;
}

.OptionsTitle {
    margin-top: 0;
    margin-bottom: 26px;
}
.Warning .OptionsTitle {
    margin-bottom: 6px;
}
.Options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.Options button:last-of-type {
    margin-left: 16px;
}

.NoValueWarning {
    font-weight: bold;
}
.WarningText {
    margin-bottom: 26px;
    line-height: 20px;
    display: flex;
}
.WarningText img {
    margin-right: 6px;
}
