.Navbar {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-bottom: 1px solid var(--lawmonitor-gray-300);
    height: 56px;
    min-height: 56px;
}

.Settings {
    display: flex;
    align-items: center;
}
.Settings img {
    height: initial;
    width: initial;
    margin-right: 10px;
    cursor: pointer;
    padding: 12px;
}
.Settings h3 {
    color: var(--lawmonitor-gray-500);
    font-size: 14px;
}

.NavbarList {
    height: 100%;
    margin: 0;
    display: flex;
    padding-left: 84px;
    margin-right: auto;
}
.NavbarList li {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    display: inline-block;
}

.NavbarLink {
    height: 100%;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    color: var(--lawmonitor-gray-500);
    padding-top: 6px;
    border-bottom-style: solid;
    border-bottom-width: 6px;
    border-bottom-color: transparent;
    line-height: 40px;
    font-size: 14px;
}
.NavbarLink.Active {
    font-weight: bold;
    color: var(--lawmonitor-blue-200);
    border-bottom-color: var(--lawmonitor-blue-200);
}

.NavbarLinkMerge.Active {
    font-weight: bold;
    border-bottom-color: var(--lawmonitor-red);
}

/* Fix for font-weight changing width */
.NavbarLink::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.CompanyLogin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.CompanyLogin h6 {
    color: var(--lawmonitor-gray-500);
    font-size: 12px;
}

.EnvName {
    position: absolute;
    top: 50%;
    right: 160px;
    transform: translateY(-50%);
    font-size: 12px;
}
.EnvNameProd {
    visibility: hidden;
    user-select: none;
}

.Badge {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    background-color: var(--lawmonitor-blue-200);
    font-size: 10px;
    font-weight: bold;
    border-radius: 9px;
    margin: 0;
    margin-left: 4px;
    padding: 1px 5px;
    height: 14px;
}

.SubMenu {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
}
.SubMenu > a {
    margin-right: 16px;
    max-height: 32px;
}

@media screen and ( max-width: 800px ) {
    .Navbar {
        border-bottom: none;
        height: 80px;
    }
}
