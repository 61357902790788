.Options {
    width: 100%;
    margin-top: 24px;
    display: flex;
}
.Options .RightSide {
    margin-left: auto;
}
.Options .RightSide button:last-of-type {
    margin-left: 16px;
}
