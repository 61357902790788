.EditNewsFeedModal {
    max-width: 875px;
    width: 100%;
    padding-top: 16px;
}
.EditNewsFeedModal > h4 {
    display: none;
}
.Content {
    width: 100%;
    height: 100%;
    margin-top: 16px;
}
.TitleInput > div {
    max-width: 100%;
}
.TitleInput p:first-of-type {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
}

.TextEditor {
    height: 200px;
}
.TextEditor > div > div:first-of-type {
    margin-bottom: 2px;
}
.TextEditor > div > div:nth-child(2) {
    border: 1px solid var(--lawmonitor-gray-500);
}
.TextEditor h5 {
    font-size: 12px;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}
.Buttons button:last-of-type {
    margin-left: 16px;
}
.Buttons .RemoveButton {
    margin-right: auto;
}
