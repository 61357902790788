.FilterbarMenu {
    position: relative;
}

.FilterbarHeader {
    width: 55px;
    height: 55px;
    padding: 14px;
    background-color: var(--lawmonitor-gray-100);
    cursor: pointer;
}
.FilterbarHeader:hover {
    background-color: var(--lawmonitor-gray-200);
}
.FilterbarHeader .Icon {
    height: 100%;
    width: 100%;
}
.Active .FilterbarHeader {
    background-color: var(--lawmonitor-blue-200);
}

.FilterbarList {
    display: none;
}
.Active .FilterbarList {
    display: block;
    position: absolute;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 0;
    list-style: none;
    background-color: #ffffff;
    top: 56px;
    right: 0;
    z-index: 1001;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}
