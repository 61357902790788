.Title {
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.Title,
.Name {
    color: var(--lawmonitor-gray-500);
}

.Name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    align-items: center;
}
.Name img {
    margin-right: 4px;
}

.Expired {
    color: red;
}

.WarnSpan {
    padding-left: 0.5em;
}

@media all and (-ms-high-contrast: none) {
    .Title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    *::-ms-backdrop,
    .Title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
